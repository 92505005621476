import { useEffect, useRef } from 'react';
import HomeOne from './pages/Home';
import { Routes, Route } from 'react-router-dom';
import ProductPage from './pages/ProductPage';
import ShopOne from './pages/ShopOne';
import MyAdds from './pages/myAdds';
import EditAdds from './pages/EditAdds/EditAdds.js';
import ShopTwo from './pages/ShopTwo';
import { useDispatch } from 'react-redux';
import { WIDTH } from './actions/types';
import AddProduct from './pages/ProductPage/addProduct';
import SignUp from './pages/SignUp/SignUp';
import Login from './pages/Login/Login';
import { AuthContextProvider } from './firebase/Auth';
import ProtectedRoute from './firebase/PrivateRoute/PrivateRoute';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

function App() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: WIDTH,
      payload: windowSize.current[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);

  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route exact path='/signup' element={<SignUp />} />
          <Route exact path='/login' element={<Login />} />
          <Route
            exact
            path='/'
            element={
              <ProtectedRoute>
                {' '}
                <HomeOne />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/shop'
            element={
              <ProtectedRoute>
                {' '}
                <ShopTwo />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/shop-search'
            element={
              <ProtectedRoute>
                {' '}
                <ShopOne />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/MyAdds'
            element={
              <ProtectedRoute>
                {' '}
                <MyAdds />{' '}
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/product-page'
            element={
              <ProtectedRoute>
                {' '}
                <ProductPage />{' '}
              </ProtectedRoute>
            }
          />
          <Route exact path='/edit-adds/:addsKey' element={<EditAdds />} />
          <Route
            exact
            path='/add-product'
            element={
              <ProtectedRoute>
                <AddProduct />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthContextProvider>
      <Analytics />
    </>
  );
}

export default App;
