import React, { useEffect, useState } from 'react';
import './index.css';
import { Fragment } from 'react';
import Slider from 'react-slick';
import Header from '../../layout/Header/index';
import Footer from '../../layout/Footer';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import CarCardUp from '../../components/Card/CarCardUp';
import CarCardLow from '../../components/Card/CarCardLow';
import {
  settingCardsShopTwo,
  settingMobile,
} from '../../components/Slider/sliderSettings';
import { useSelector } from 'react-redux';
// import { parse } from 'uuid';

const ShopTwo = () => {
  const widthh = useSelector((state) => state.settings.width);
  const [width, setWidth] = useState(widthh);

  const [details, setDetails] = useState([]);
  // const [removepadding, setRemovePadding] = useState(0);
  const [final, setFinal] = useState([]);
  const [delay, setDelay] = useState(false);
  const [finalSubUnderSum, setFinalSubUnderSum] = useState([]);

  useEffect(() => {
    setWidth(widthh);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthh]);

  //let userId01 = JSON.stringify(localStorage.getItem('userId'));
  let results = [];
  //const [userId, setUserId] = useState(userId01);
  //const navigate = useNavigate();
  useEffect(() => {
    GetCardetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const moreDetails = (id) => {
  //   // event.preventDefault();
  //   navigate(`/product-page?id=${id}`);
  // };

  async function GetCardetails() {
    setDelay(false);
    const local = [];
    let filterResults = [];
    const querySnapshot = await getDocs(collection(db, 'carDetail'));
    querySnapshot.forEach((doc) => {
      local.push({
        ...doc.data(),
        key: doc.id,
      });
    });
    await setDetails(local);
    await setFinal(local);
    await local.map((det) => {
      let removeSing = det && det.price && det.price.replace(',', '');
      if (removeSing && parseInt(removeSing) < 50000) {
        filterResults.push(det);
      }
    });
    setFinalSubUnderSum(filterResults);

    setTimeout(() => {
      setDelay(true);
    }, 100);
  }

  let mainFilter = useSelector((state) => state.settings.mainFilter);

  const myFilter = (element) => {
    if (element.carType === mainFilter.carType && mainFilter.man === '')
      // all the note is for the code above
      // check if -> carType && man from filter fit the data
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      mainFilter.mod === '' &&
      mainFilter.year === ''
    )
      // check else if -> carType && man from filter fit the data
      return true;
    else if (
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod === mainFilter.mod &&
        mainFilter.smod === '' &&
        mainFilter.year === ' ') ||
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        mainFilter.mod === '' &&
        mainFilter.smod === '' &&
        element.year === String(mainFilter.year) &&
        mainFilter.engCap === '')
    )
      // its for 2 cases *above
      //1. element.smod === mainFilter.smod
      // 2. element.smod === "" // for case the user don't enter smod & skip to year.
      // from now will be 2 caese for for each 'smod' becuse the problem above.
      return true;
    else if (
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod === mainFilter.mod &&
        element.smod === mainFilter.smod &&
        mainFilter.year === '') ||
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod !== '' &&
        mainFilter.smod === '' &&
        element.year === String(mainFilter.year) &&
        mainFilter.engCap === '')
    )
      // its for 2 cases *above
      //1. element.smod === mainFilter.smod
      // 2. element.smod === "" // for case the user don't enter smod & skip to year.
      // from now will be 2 caese for for each 'smod' becuse the problem above.
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      element.mod === mainFilter.mod &&
      element.smod === mainFilter.smod &&
      element.year === String(mainFilter.year) &&
      String(mainFilter.engCap) === ''
    )
      // for case both smod & year not -> ""
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      mainFilter.gearBox === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      mainFilter.area === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      element.area === mainFilter.area &&
      String(mainFilter.hand) === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      element.area === mainFilter.area &&
      element.hand === String(mainFilter.hand)
    )
      return true;
    else if (
      element.carType !== mainFilter.carType &&
      element.man !== mainFilter.man &&
      (element.mod !== mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod !== mainFilter.smod || mainFilter.smod !== '') &&
      element.year !== String(mainFilter.year) &&
      element.engCap !== String(mainFilter.engCap) &&
      element.gearBox !== mainFilter.gearBox &&
      element.area !== mainFilter.area &&
      element.hand !== String(mainFilter.hand)
    )
      return false;
    else return false;
  };
  let elseResults = [];
  useEffect(() => {
    setDelay(false);

    // Iterate through each element
    details.forEach((element) => {
      // Push in the filteredArr if
      // it returns false
      if (myFilter(element) === true) {
        results.push(element);
      } else {
        elseResults.push(element);
      }
    });

    setFinal(results);
    setTimeout(() => {
      setDelay(true);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFilter]);

  const prods = final.map((det, index) => {
    return (
      <div key={index} className='inner-home-2-slide'>
        <div className='product-adds home-2-product-adds home-3-product-adds'>
          <CarCardUp det={det} index={index} />
        </div>
      </div>
    );
  });

  // save for later
  // function removeCommas(str) {
  //   while (str.search(',') >= 0) {
  //     str = (str + '').replace(',', '');
  //   }
  //   return str;
  // }

  const Expprods =
    finalSubUnderSum &&
    finalSubUnderSum.map((det, index) => {
      return (
        <div key={index} className='inner-home-2-slide'>
          <div className='product-adds home-2-product-adds home-3-product-adds'>
            <CarCardLow det={det} index={index} />
          </div>
        </div>
      );
    });

  return (
    <>
      <div className='wrapper' id='main'>
        <Header />
        <div className='tabs-content pro-content home-two-sec'>
          <div
            className={
              width <= 1024
                ? 'container-fluid contianner-fluid-padding'
                : 'container-fluid mr-side-t'
            }
            id='remove-side-padding'
          >
            <div className='row img-con'>
              <div className='col-12 col-lg-12 btn-div-pp'>
                <a href='/' className='btn slider-btn-nnn swipe-to-top'>
                  {/* BACK BUTTON */}
                  <span className='slider-btn-textt'>חזרה</span>
                  <img
                    alt=''
                    className='btn-img-m'
                    src='/assets/new-images/right.png'
                  />
                </a>
                <div className=' icon-d-m'>
                  <span className='btn-extra-title-2'>חדשים באתר:</span>
                </div>
              </div>
            </div>

            <div>
              <div className=''>
                <div className='row img-con2'>
                  <div className='col-12 '>
                    {/* Tab panes */}
                    <div className=''>
                      <div>
                        <div className='row'>
                          <div className='col-12 home-three-main-slider'>
                            {width > 600 ? (
                              <Slider {...settingCardsShopTwo}>
                                {final.length > 0 ? (
                                  prods
                                ) : delay ? (
                                  <h1
                                    className='home-3-title-not-result'
                                    style={{ color: 'white' }}
                                  >
                                    לא נמצאו מודעות{' '}
                                  </h1>
                                ) : (
                                  <div className='spinnerRow'>
                                    {' '}
                                    <i className='fa fa-spinner fa-spin' />{' '}
                                  </div>
                                )}
                              </Slider>
                            ) : (
                              <Slider {...settingMobile}>
                                {final.length > 0 ? (
                                  prods
                                ) : delay ? (
                                  <h1
                                    className='home-3-title-not-result-mobile'
                                    style={{ color: 'black' }}
                                  >
                                    לא נמצאו מודעות{' '}
                                  </h1>
                                ) : (
                                  <div className='spinnerRow-mobile-black'>
                                    {' '}
                                    <i className='fa fa-spinner fa-spin' />{' '}
                                  </div>
                                )}
                              </Slider>
                            )}
                          </div>
                        </div>
                        {/* 1st tab */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='row img-con2'>
                  <div className='col-12 '>
                    <div className='col-12 col-lg-12 btn-div-pp'>
                      <a href='https://www.halalitcar.co.il/' className=''></a>
                      <div className='icon-d-m'>
                        {final && (
                          <span className='btn-extra-title-21'>
                            {' '}
                            רכבים עד ₪50,000
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Tab panes */}
                    <div className=''>
                      <div>
                        <div className='row'>
                          <div className='col-12 home-three-main-slider'>
                            {width > 600 ? (
                              <Slider {...settingCardsShopTwo}>
                                {final.length > 0 ? (
                                  Expprods
                                ) : delay ? (
                                  <h1
                                    className='home-3-title-not-result'
                                    style={{ color: 'white' }}
                                  >
                                    לא נמצאו מודעות{' '}
                                  </h1>
                                ) : (
                                  <div className='spinnerRow'>
                                    {' '}
                                    <i className='fa fa-spinner fa-spin' />{' '}
                                  </div>
                                )}
                              </Slider>
                            ) : (
                              <Slider {...settingMobile}>
                                {final.length > 0 ? (
                                  Expprods
                                ) : delay ? (
                                  <h1
                                    className='home-3-title-not-result-mobile'
                                    style={{ color: 'black' }}
                                  >
                                    לא נמצאו מודעות{' '}
                                  </h1>
                                ) : (
                                  <div className='spinnerRow-mobile-black'>
                                    {' '}
                                    <i className='fa fa-spinner fa-spin' />{' '}
                                  </div>
                                )}
                              </Slider>
                            )}
                          </div>
                        </div>
                        {/* 1st tab */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //footer style One */}
        <Footer />
      </div>
      <div className='mobile-overlay' />
    </>
  );
};

export default ShopTwo;
