import './addProduct.css';
import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import cars_filter_data from '../../cars_filter_data.json';
import { db } from '../../firebase/config';
import { storage } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { UserAuth } from '../../firebase/Auth';
import { collection, addDoc } from 'firebase/firestore';
import Header from '../../layout/Header/index';
import Footer from '../../layout/Footer/index';
import { toast, ToastContainer } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

import { v4 } from 'uuid';
const AddProduct = () => {
  const width = useSelector((state) => state.settings.width);

  const { user } = UserAuth();
  // const [carType, setCarType] = useState([]);
  // const [catType, setCatType] = useState([]);
  const [data, setData] = useState(cars_filter_data);
  const [area, setArea] = useState([]);
  const [hand, setHand] = useState([]);
  const [price, setPrice] = useState([]);
  const [km, setKm] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);
  const [desc, setDesc] = useState([]);
  const [fullName, setFullName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [imageUpload, setImageUpload] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [imgLoading, setImgLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [mans, setMans] = useState([]);
  const [mods, setMods] = useState([]);
  const [smods, setSMods] = useState([]);
  const [years, setYears] = useState([]);
  const [gearBoxes, setGearBoxes] = useState([]);
  const [engCaps, setEngCaps] = useState([]);
  // const imageListRef = ref(storage, 'images/');
  const inputRef = useRef(null);
  // const now = new Date().toLocaleTimeString();
  // let [time, setTime] = React.useState(now);
  const carTypes = [
    'פרטי',
    'מסחרי',
    "ג'יפים",
    'משאיות',
    'אופנועים',
    'קטנועים',
    'טרקטורונים',
    'מיוחדים',
  ];
  // const [customErrors, setErrors] = useState({
  //   carType: undefined,
  //   man: undefined,
  //   mod: undefined,
  //   smod: undefined,
  // });
  const [values, setValues] = useState({
    carType: '',
    man: '',
    mod: '',
    smod: '',
    year: '',
    price: '',
    engCap: '',
    gearBox: '',
    km: '',
    ownerShip: '',
    desc: '',
    fullName: '',
    phone: '',
    hand: '',
    area: '',
  });

  useEffect(() => {
    let array = [];
    for (let i in data) {
      array.push(data[i]);
    }
    setData(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      carType: '',
      man: '',
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      // carType: Yup.string(),
      // man: Yup.string(),
      // mod: Yup.string(),
      // smod: Yup.string(),
      // year: Yup.string(),
      // engCap: Yup.string(),
      // gearBox: Yup.string(),
      // price: Yup.number().required('Required!'),
      // km: Yup.number().required('Required!'),
      // ownerShip: Yup.string().required('Required!'),
      // desc: Yup.string().required('Required!'),
      // fullName: Yup.string().required('Required!'),
      // phone: Yup.string().required('Required!'),
      // area: Yup.string().required('Required!'),
    }),

    // onSubmit: async (value, { resetForm }) => {
    //   console.log('submit', values);
    //   if (values.carType) {
    //     value.carType = values.carType;
    //   } else {
    //     formik.errors.carType = 'Required!';
    //     formik.touched.carType = true;
    //     return;
    //   }
    //   if (values.man) {
    //     value.man = values.man;
    //   } else {
    //     formik.errors.man = 'Required!';
    //     formik.touched.man = true;
    //     return;
    //   }
    //   if (values.mod) {
    //     value.mod = values.mod;
    //   } else {
    //     formik.errors.mod = 'Required!';
    //     formik.touched.mod = true;
    //     return;
    //   }
    //   if (values.smod) {
    //     value.smod = values.smod;
    //   } else {
    //     formik.errors.smod = 'Required!';
    //     formik.touched.smod = true;
    //     return;
    //   }
    //   if (values.year) {
    //     value.year = values.year;
    //   } else {
    //     formik.errors.year = 'Required!';
    //     formik.touched.year = true;
    //     return;
    //   }
    //   if (values.engCap) {
    //     value.engCap = values.engCap;
    //   } else {
    //     formik.errors.engCap = 'Required!';
    //     formik.touched.engCap = true;
    //     return;
    //   }
    //   if (values.gearBox) {
    //     value.gearBox = values.gearBox;
    //   } else {
    //     formik.errors.gearBox = 'Required!';
    //     formik.touched.gearBox = true;
    //     return;
    //   }
    //   if (values.ownerShip) {
    //     value.gearBox = values.gearBox;
    //   } else {
    //     formik.errors.gearBox = 'Required!';
    //     formik.touched.gearBox = true;
    //     return;
    //   }
    //   if (values.price) {
    //     value.gearBox = values.gearBox;
    //   } else {
    //     formik.errors.gearBox = 'Required!';
    //     formik.touched.gearBox = true;
    //     return;
    //   }
    //   if (values.km) {
    //     value.gearBox = values.gearBox;
    //   } else {
    //     formik.errors.gearBox = 'Required!';
    //     formik.touched.gearBox = true;
    //     return;
    //   }
    //   if (values.desc) {
    //     value.desc = values.desc;
    //   } else {
    //     formik.errors.desc = 'Required!';
    //     formik.touched.desc = true;
    //     return;
    //   }
    //   if (values.fullName) {
    //     value.fullName = values.fullName;
    //   } else {
    //     formik.errors.fullName = 'Required!';
    //     formik.touched.fullName = true;
    //     return;
    //   }
    //   if (values.phone) {
    //     value.phone = values.phone;
    //   } else {
    //     formik.errors.phone = 'Required!';
    //     formik.touched.phone = true;
    //     return;
    //   }
    //   if (values.area) {
    //     value.area = values.area;
    //   } else {
    //     formik.errors.area = 'Required!';
    //     formik.touched.area = true;
    //     return;
    //   }
    //   try {
    //     const docRef = await addDoc(collection(db, 'carDetail'), {
    //       userId: user.uid,
    //       carType: value.carType,
    //       man: value.man,
    //       mod: value.mod,
    //       smod: value.smod,
    //       year: value.year,
    //       price: value.price,
    //       engCap: value.engCap,
    //       gearBox: value.gearBox,
    //       km: value.km,
    //       ownerShip: value.ownerShip,
    //       images: imageUrls,
    //       desc: value.desc,
    //       fullName: value.fullName,
    //       phone: value.phone,
    //       // area: value.area,
    // hand: '',
    //       area: 'תל אביב',
    //     });
    //     setLoading(false);
    //     resetForm();
    //     toast.success('Deatil Saved');
    //   } catch (e) {
    //     setLoading(false);
    //     toast.error('Detail not Saved');
    //   }
    // },
  });

  function isFull() {
    if (
      user.uid &&
      values.carType !== '' &&
      values.man !== '' &&
      values.year !== '' &&
      values.engCap !== '' &&
      values.gearBox !== '' &&
      price !== [] &&
      km !== [] &&
      ownerShip !== [] &&
      desc !== [] &&
      fullName !== [] &&
      phone !== []
    )
      setSubmit(true);
    else setSubmit(false);
  }

  const changeHandler = async (e) => {
    let newImage = [];
    for (let i = 0; i < e.target.files.length < 5; i++) {
      let t = e.target.files[i];
      //newImage['id'] = v4();
      if (t) {
        newImage[i] = t;

        newImage[i].id = await v4();
        // await setImageUpload((prevState) => [...prevState, newImage]);
        //setImageUpload([newImage]);
      } else break;
    }
    uploadImage(...[newImage]);
    //let x = imageUpload;

    //await uploadImage();
  };

  const uploadImage = async (newImage) => {
    if (newImage === []) return <h1>בבקשה להעלות תמונה</h1>;
    // const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    const imageRef = newImage.map((img) =>
      ref(storage, `images/${img.name + img.id}`)
    );

    for (let i = 0; i < newImage.length; i++) {
      // setImgLoading(true);
      const byte = await uploadBytes(imageRef[i], newImage[i]);
      // setImgLoading(false);
      toast.success('התמונה עלתה בהצלחה');
      const url = await getDownloadURL(byte.ref);
      await setImageUrls((prev) => [...prev, url]);
    }
  };

  function handleChange(event) {
    setValues({
      carType: event.target.value,
      man: '',
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    });
    setMods([]);
    setSMods([]);
    setTypes([]);
    setYears([]);
    setGearBoxes([]);
    setEngCaps([]);
    setArea([]);
    setHand([]);

    let carTypeSelected = event.target.value;

    const res = data.filter((el) =>
      el.carType.filter((t) => t === carTypeSelected)
    );
    setTypes(data[0][carTypeSelected]);
    setMans([...new Set(data[0][carTypeSelected].map((el) => el.man))]);
  }

  function handleChangesMan(event) {
    setMods([]);
    setSMods([]);
    setYears([]);
    setGearBoxes([]);
    setEngCaps([]);
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: event.target.value,
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    if (values.carType === 'משאיות') {
      setYears(data[0].year);
    } else {
      const res = types.filter((el) => el.man === event.target.value);
      setMods([...new Set(res.map((el) => el.mod))]);
    }
  }

  function handleChangesMod(event) {
    setSMods([]);
    setYears([]);
    setGearBoxes([]);
    setEngCaps([]);
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: event.target.value,
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    if (values.carType === 'פרטי') {
      const res = types.filter((el) => el.mod === event.target.value);
      setSMods([...new Set(res.map((el) => el.smod))]);
      setYears(data[0].year);
    } else {
      setYears(data[0].year);
    }
  }

  function handleChangesSMod(event) {
    setYears([]);
    setGearBoxes([]);
    setEngCaps([]);
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: event.target.value,
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    setYears(data[0].year);
  }

  function handleChangesYears(event) {
    setGearBoxes([]);
    setEngCaps([]);
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: prevValues.smod,
      year: event.target.value,
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    setEngCaps(data[0].engCap);
  }
  function handleChangesEngCap(event) {
    setGearBoxes([]);
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: prevValues.smod,
      year: prevValues.year,
      price: '',
      engCap: event.target.value,
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    setGearBoxes(data[0].gearBox);
  }
  function handleChangesGearBoxes(event) {
    isFull();
    setArea([]);
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: prevValues.smod,
      year: prevValues.year,
      price: '',
      engCap: prevValues.engCap,
      gearBox: event.target.value,
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    }));
    setArea(data[0].area);
  }

  function handleChangesArea(event) {
    isFull();
    setHand([]);
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: prevValues.smod,
      year: prevValues.year,
      price: '',
      engCap: prevValues.engCap,
      gearBox: prevValues.gearBox,
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: event.target.value,
    }));
    setHand(data[0].hand);
  }

  function handleChangesHand(event) {
    setValues((prevValues) => ({
      carType: prevValues.carType,
      man: prevValues.man,
      mod: prevValues.mod,
      smod: prevValues.smod,
      year: prevValues.year,
      price: '',
      engCap: prevValues.engCap,
      gearBox: prevValues.gearBox,
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: event.target.value,
      area: prevValues.area,
    }));

    //let x = values.hand;
    // const res = types.filter((el) => el.gearBox===event.target.value);
    // setArea([...new Set(res.map((el) => el.area))]);
  }
  // beni
  // function handleInput(event) {
  //   const [area, setArea] = useState('');
  // const [price, setPrice] = useState('');
  // const [km, setKm] = useState('');
  // const [ownerShip, setOwnerShip] = useState('');
  // const [desc, setDesc] = useState('');
  // const [fullName, setFullName] = useState('');
  // const [phone, setPhone] = useState('');

  //   setEngCaps([]);
  //   const res = data.filter((el) =>
  //     el.carType.filter((t) => t === event.target.value)
  //   );
  //   // const res = data.filter((el) => el.carType===event.target.value);
  //   setTypes(res);
  //   let x = [...new Set(res.map((el) => el.man))];
  //   setMans(x);
  // }

  // function handleChangesArea(event) {
  //   setValues((prevValues) => ({
  //     carType: prevValues.carType,
  //     man: prevValues.man,
  //     mod: prevValues.mod,
  //     smod: prevValues.smod,
  //     year: prevValues.year,
  //     price: '',
  //     engCap: prevValues.engCap,
  //     gearBox: prevValues.gearBox,
  //     km: '',
  //     ownerShip: '',
  //     desc: '',
  //     fullName: '',
  //     phone: '',
  //     area: event.target.value,
  //   }));
  //   //const res = types.filter((el) => el.smod===event.target.value);
  //   //setGearBoxes([...new Set(res.map((el) => el.gearBox))]);
  // }

  // useEffect(() => {
  //   const userId01 = JSON.stringify(localStorage.getItem('userId'));
  //   setUserId(userId01);
  // }, [imageUrls]);

  // const changeHandler = (e) => {
  //   e.preventDefault();
  //   for (let i = 0; i < e.target.files.length < 5; i++) {
  //     const newImage = e.target.files[i];
  //     newImage['id'] = v4();
  //     setImageUpload((prevState) => [...prevState, newImage]);
  //     setBtn02Enable(true);
  //   }
  // };
  // const uploadImage = async (e) => {
  //   e.preventDefault();
  //   if (imageUpload===[]) return <h1>Please Upload Image</h1>;
  //   const imageRef = imageUpload.map((img) =>
  //     ref(storage, `images/${img.name + v4()}`)
  //   );

  //   for (let i = 0; i < imageUpload.length; i++) {
  //     setImgLoading(true);
  //     const byte = await uploadBytes(imageRef[i], imageUpload[i]);

  //     console.log("Image succesfully uploaded");
  //     const url = await getDownloadURL(byte.ref);
  //     setImageUrls((prev) => [...prev, url]);
  //   }
  //   setBtn02Enable(false);
  //   setImgLoading(false);
  //   setBtnEnable(true);
  // };

  // good
  // for later
  // const handleCarType = (value) => {
  //   // .preventDefault();
  //   setCarType(value);

  //   var btnContainer = document.getElementById('myDIV');

  //   // Get all buttons with class="btn" inside the container
  //   var btns = btnContainer.getElementsByClassName('wrapper_menu');

  //   // Loop through the buttons and add the active class to the current/clicked button
  //   for (var i = 0; i < btns.length; i++) {
  //     btns[i].addEventListener('click', function () {
  //       var current = document.getElementsByClassName('active02');

  //       // If there's no active class
  //       if (current.length > 0) {
  //         current[0].className = current[0].className.replace(' active02', '');
  //       }

  //       // Add the active class to the current/clicked button
  //       this.className += ' active02';
  //     });
  //   }
  //   console.log('adgvdfxxz cartype :', carType);
  // };

  // const categoryType = (value01) => {
  //   setCatType(value01);
  //   var btnContainer = document.getElementById('MyDiv01');

  //   // Get all buttons with class="btn" inside the container
  //   var btns = btnContainer.getElementsByClassName('menu_categoty');

  //   // Loop through the buttons and add the active class to the current/clicked button
  //   for (var i = 0; i < btns.length; i++) {
  //     btns[i].addEventListener('hover', function () {
  //       var current = document.getElementsByClassName('active01');

  //       // If there's no active class
  //       if (current.length > 0) {
  //         current[0].className = current[0].className.replace(' active01', '');
  //       }

  //       // Add the active class to the cxcvbvbcxgburrent/clicked button
  //       this.className += ' active01';
  //     });
  //   }
  // };

  const resetAll = () => {
    setLoading(false);
    setValues({
      carType: '',
      man: '',
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    });
    setArea([]);
    setPrice([]);
    setKm([]);
    setHand([]);
    setOwnerShip([]);
    setDesc([]);
    setFullName([]);
    setPhone([]);
    setImageUrls([]);
    setImageUpload([]);
    inputRef.current.value = null;
    isFull();
  };

  const handleSubmitForm = async (e) => {
    setLoading(true);
    // done in this way, becuse is no time
    // the on submit func in formik nor work, i do what i can, sorry.
    if (submit && values.carType !== '') {
      let nf = new Intl.NumberFormat();

      if (imageUpload === []) await uploadImage();

      let tempData = {
        userId: user.uid,
        carType: values.carType,
        man: values.man,
        mod: values.mod,
        smod: values.smod,
        year: values.year,
        engCap: values.engCap,
        gearBox: values.gearBox,
        hand: values.hand,
        area: values.area,
        km: nf.format(km),
        desc: desc,
        ownerShip: ownerShip,
        price: nf.format(price),
        fullName: fullName,
        phone: phone,
        images: imageUrls,
      };
      try {
        const docRef = await addDoc(collection(db, 'carDetail'), tempData);
        docRef && console.log(docRef);
        setLoading(false);
        //resetForm();
        resetAll();
        toast.success('המידע נשמר');
      } catch (e) {
        setLoading(false);
        toast.error('המידע לא נשמר');
      }
      setLoading(false);
    } else {
      toast.error(' נא למלאות את כל השדות לפני שמירה');
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className='product_detail_Main_wrapper'>
        <Form onSubmit={formik.handleSubmit} className='text-center'>
          <div className='container addPro'>
            <div className='row justify-content-center'>
              <div className='col-lg-9'>
                <a
                  onClick={() => {
                    window.history.back();
                  }}
                  className='btn slider-btn-mmm swipe-to-top'
                >
                  <span className='slider-btn-textt'>חזרה</span>
                  <img
                    alt=''
                    className='btn-img-m'
                    src='/assets/new-images/right.png'
                  />
                </a>
              </div>
            </div>
            <div className='one' id='myDIV'>
              <div className='row justify-content-center mt-3 '>
                {width > 600 ? (
                  <h1 className='textCustom-title-6'>פרסום מודעה חדשה</h1>
                ) : (
                  <h1 className='textCustom-title-5'>פרסום מודעה חדשה</h1>
                )}
                {/* מסחרי */}
                {/* <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className='textTitle heading'> ג’יפים</h5>
                    <div
                      onClick={() => handleCarType('Jeep')}
                      id='Jeep'
                      className='pointer wrapper_menu'
                    >
                      <img
                        className='imgCustom'
                        value='Jeep'
                        src='/assets/new-images/jeepcabrio1.png'
                        alt='jeep'
                      />
                    </div>
                  </div>
                </div> */}
                {/* מסחרי */}
                {/* <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className='textTitle heading'>מסחרי</h5>
                    <div
                      onClick={() => handleCarType('Commercial')}
                      id='Commercial'
                      className='pointer wrapper_menu'
                    >
                      <img
                        value='Commercial'
                        className='imgCustom'
                        // onClick={(e)=>setCarType("Commercial")}
                        src='/assets/new-images/מסחרי1.png'
                        alt='wagon'
                      />
                    </div>
                  </div>
                </div> */}
                {/* פרטי */}
                {/* <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className=' textTitle heading'>פרטי</h5>
                    <div
                      onClick={() => handleCarType('Private')}
                      id='Private'
                      className='pointer wrapper_menu'
                    >
                      <img
                        value='Private'
                        className='imgCustom'
                        // onClick={(e)=>setCarType("Private")}
                        src='/assets/new-images/פרטי1.png'
                        alt='wagon'
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              {/* קטנועים */}
              {/* <div className='row justify-content-center'>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className='textTitle heading'>קטנועים</h5>
                    <div
                      onClick={() => handleCarType('Scooters')}
                      id='Scooters'
                      className='pointer wrapper_menu'
                    >
                      <img
                        className='imgCustom'
                        value='Scooters'
                        // onClick={(e)=>setCarType("Scooters")}
                        src='/assets/new-images/scooter.png'
                        alt='motorcycle'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className='textTitle heading'>אופנעים</h5>
                    <div
                      onClick={() => handleCarType('MotorCycle')}
                      id='MotorCycle'
                      className='pointer wrapper_menu'
                    >
                      <img
                        value='MotorCycle'
                        className='imgCustom'
                        // onClick={(e)=>setCarType("MotorCycle")}
                        src='/assets/new-images/heavybike.png'
                        alt='heavybike'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div className='main_category text-center'>
                    <h5 className='textTitle heading'>משאיות</h5>
                    <div
                      onClick={() => handleCarType('Truck')}
                      id='Truck'
                      className='pointer wrapper_menu'
                    >
                      <img
                        value='Trucks'
                        className='imgCustom'
                        // onClick={(e)=>setCarType("Trucks")}
                        src='/assets/new-images/truck.png'
                        alt='truck'
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div id='MyDiv01'>
              <div className='row justify-content-center mt-4'>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div
                    onClick={() => categoryType('Special')}
                    // onClick={setCatType("Special")}
                    className='pointer menu_categoty'
                  >
                    <h5 className='buttonCuston mb-0'>מיוחדים</h5>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div
                    onClick={() => categoryType('ATVs')}
                    className='pointer menu_categoty'
                  >
                    <h5 className='buttonCuston mb-0'>טרקטורונים</h5>
                  </div>
                </div>
                <div className='col-lg-3 col-md-4 col-sm-4 first_row_category'>
                  <div
                    onClick={() => categoryType('vessel')}
                    className='pointer menu_categoty'
                  >
                    <h5 className='buttonCuston mb-0'>כלי שייט</h5>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='row justify-content-center hello_world'>
              <div className='rtl-main banners-content col-lg-9 col-md-12 col-sm-12'>
                <label className='ui horizontal label'>סוג הרכב </label>
                <div className=' ui input large p-0'>
                  <select
                    onChange={handleChange}
                    value={values.carType}
                    name='carType'
                    required
                    className='pointer textCustom form-control'
                  >
                    <option
                      className='textCustom'
                      value=''
                      label='בחר מבין האפשרויות: '
                    />
                    {carTypes.map((car) => {
                      return (
                        <option
                          className='textCustom'
                          key={car}
                          value={car}
                          label={car}
                        >
                          {car}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* {formik.errors.carType && formik.touched.carType && (
                  <p>{formik.errors.carType}</p>
                )} */}
                <label className='ui horizontal label'>בחר יצרן</label>
                <select
                  onChange={handleChangesMan}
                  value={values.man}
                  name='man'
                  className='pointer textCustom form-control'
                >
                  <option
                    className='textCustom'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {mans.map((man) => {
                    return (
                      <option
                        className='textCustom'
                        key={man}
                        value={man}
                        label={man}
                      >
                        {man}
                      </option>
                    );
                  })}
                </select>
                {values.carType !== 'משאיות' ? (
                  <div>
                    <label className='ui horizontal label'>בחר דגם</label>
                    <select
                      onChange={handleChangesMod}
                      value={values.mod}
                      name='mod'
                      className='pointer textCustom form-control'
                    >
                      <option
                        className='textCustom'
                        value=''
                        label='בחר מבין האפשרויות: '
                      />
                      {mods.map((mod) => {
                        return (
                          <option
                            className='textCustom'
                            key={mod}
                            value={mod}
                            label={mod}
                          >
                            {mod}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <span></span>
                )}
                {values.carType === 'פרטי' ? (
                  <div>
                    <label className='ui horizontal label'>בחר תת דגם</label>
                    <select
                      onChange={handleChangesSMod}
                      value={values.smod}
                      name='smod'
                      className='pointer textCustom form-control'
                    >
                      <option
                        className='textCustom'
                        value=''
                        label='בחר מבין האפשרויות: '
                      />
                      {smods.map((smod) => {
                        return (
                          <option
                            className='textCustom'
                            key={smod}
                            value={smod}
                            label={smod}
                          >
                            {smod}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <span></span>
                )}
                <label className='ui horizontal label'>בחר שנה</label>
                <select
                  onChange={handleChangesYears}
                  value={values.year}
                  name='year'
                  required
                  className='pointer textCustom form-control'
                >
                  <option
                    className='textCustom'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {years.map((year) => {
                    return (
                      <option
                        className='textCustom'
                        key={year}
                        value={year}
                        label={year}
                      >
                        {year}
                      </option>
                    );
                  })}
                </select>
                <label className='ui horizontal label '>בחר נפח מנוע</label>
                <select
                  onChange={handleChangesEngCap}
                  value={values.engCap}
                  name='engCap'
                  required
                  className='pointer textCustom form-control'
                >
                  <option
                    className='textCustom'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {engCaps.map((eng) => {
                    return (
                      <option
                        className='textCustom'
                        key={eng}
                        value={eng}
                        label={eng}
                      >
                        {eng}
                      </option>
                    );
                  })}
                </select>
                <label className='ui horizontal label'>
                  בחר סוג תיבת הילוכים
                </label>
                <select
                  onChange={handleChangesGearBoxes}
                  value={values.gearBox}
                  name='gearBox'
                  required
                  className='pointer textCustom form-control p-0'
                >
                  <option
                    className='textCustom'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {gearBoxes.map((gear) => {
                    return (
                      <option
                        className='textCustom'
                        key={gear}
                        value={gear}
                        label={gear}
                      >
                        {gear}
                      </option>
                    );
                  })}
                </select>
                <label className='ui horizontal label '>אזור בארץ </label>
                <select
                  id='area'
                  required
                  onChange={handleChangesArea}
                  value={values.area}
                  name='area'
                  className='pointer textCustom form-control p-0'
                  //class='form-control input'
                >
                  <option
                    className='textCustom area'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {area.map((ar) => {
                    return (
                      <option
                        className='textCustom'
                        key={ar}
                        value={ar}
                        label={ar}
                      >
                        {ar}
                      </option>
                    );
                  })}
                </select>
                <label className='ui horizontal label'>יד</label>
                <select
                  id='hand'
                  required
                  onChange={handleChangesHand}
                  value={values.hand}
                  name='hand'
                  className='pointer textCustom form-control p-0'
                  //class='form-control input'
                >
                  <option
                    className='textCustom'
                    value=''
                    label='בחר מבין האפשרויות: '
                  />
                  {hand.map((ha) => {
                    return (
                      <option
                        className='textCustom'
                        key={ha}
                        value={ha}
                        label={ha}
                      >
                        {ha}
                      </option>
                    );
                  })}
                </select>
                <label className='ui horizontal label '>בעלות נוכחית</label>
                <input
                  type='text'
                  id='ownerShip'
                  name='ownerShip'
                  required
                  // onChange={formik.handleChange}
                  onChange={(e) => setOwnerShip(e.target.value)}
                  value={ownerShip}
                  className='textCustom form-control input'
                />
                <label className='ui horizontal label'>מחיר</label>
                <input
                  min='0'
                  required
                  type='number'
                  id='price'
                  name='price'
                  //onChange={formik.handleChange}
                  //value={formik.values.price}
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  className='textCustom form-control input'
                />
                <label className='ui horizontal label'>הקלד ק”מ</label>
                <div className='ui input large p-0'>
                  <input
                    type='number'
                    min='0'
                    required
                    id='km'
                    name='km'
                    //onChange={formik.handleChange}
                    //value={formik.values.km}
                    onChange={(e) => setKm(e.target.value)}
                    value={km}
                    className='textCustom form-control input'
                  />
                </div>
                <label className='pointer grrrr'>
                  <img src='/assets/new-images/cameraon.png' /> בחר תמונה
                </label>
                <div className='pointer ui input large p-0'>
                  <input
                    ref={inputRef}
                    type='file'
                    multiple
                    max='6'
                    className='pointer textCustom form-control input'
                    accept='.jpg,.png,.jpeg'
                    onChange={changeHandler}
                  />
                  {/* <Button
                    type='button'
                    variant='primary'
                    onClick={uploadImage}
                    size='lg'
                    className='imgBtg'
                    block
                  >
                    {loading ? (
                      <ClipLoader size={25} color='white' loading />
                    ) : (
                      'העלאת תמונה'
                    )}
                  </Button>
                  <ToastContainer /> */}
                </div>

                <label> תיאור המוצר </label>
                <textarea
                  //onChange={formik.handleChange}
                  onChange={(e) => setDesc(e.target.value)}
                  className='textCustom form-control '
                  value={desc}
                  id='exampleFormControlTextarea1'
                  rows='3'
                  required
                ></textarea>
                {/* Shoaib */}
                <label>שם מלא</label>
                <input
                  required
                  //onChange={formik.handleChange}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className='textCustom form-control input'
                />
                <label>מספר טלפון</label>
                <input
                  required
                  onChange={formik.handleChange}
                  //value={formik.values.phone}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className='textCustom form-control input'
                />
                <br />
                <div className='formSubmit'>
                  <Button
                    type='submit'
                    variant='primary'
                    //onSubmit={formik.handleSubmit}
                    onClick={handleSubmitForm}
                    className='addButton'
                    disabled={!submit}
                    size='lg'
                  >
                    {loading ? (
                      <ClipLoader size={25} color='white' loading />
                    ) : (
                      'שמירה'
                    )}
                  </Button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </section>
      <Footer />
    </>
  );
};
export default AddProduct;
