import React, { useState, useEffect, useRef } from 'react';
import { Fragment } from 'react';
import Slider from 'react-slick';
import Header from '../../layout/Header/index';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../layout/Footer';
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../firebase/config';
// import { useLocation } from "react-router-dom";

const MyAdds = () => {
  const [userId, setUserId] = useState();
  const [delay, setDelay] = useState(false);
  const [final, setFinal] = useState([]);

  const navigate = useNavigate();
  const openSearchbar = useSelector((state) => state.settings.opneSearchBar);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [width, setWidth] = useState(windowSize.current[0]);
  useEffect(() => {
    setWidth(windowSize.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);

  // const  = props;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const search = useLocation().search;
  //const search = useLocation().search;
  const filteredData = async () => {
    const local = [];
    if (userId) {
      const userIdTemp = userId.slice(1, -1);

      const q = query(
        collection(db, 'carDetail'),
        where('userId', '==', userIdTemp)
      );
      // const q =   query(collection(db, "carDetail") .where("userId", "==", userId ));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        local.push({
          ...doc.data(),
          key: doc.id,
        });
      });
      setFinal(local);
      // console.log('My adds');
      // console.log(final, 'Final');
    }
  };
  useEffect(() => {
    const userId01 = JSON.stringify(localStorage.getItem('userId'));
    setUserId(userId01);
    filteredData();
    setTimeout(() => {
      setDelay(true);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const moreDetails = (id) => {
    // event.preventDefault();
    navigate(`/product-page?id=${id}`);
  };
  const onEdit = (carId) => navigate(`/edit-adds/${carId}`);

  const onDelete = async (carId) => {
    //console.log(final);
    if (window.confirm('האם אתה בטוח שאתה רוצה למחוק את המודעה')) {
      await deleteDoc(doc(db, 'carDetail', carId));
      await filteredData();
      toast.success('  נמחק בהצלחה');
    }
  };

  return (
    <Fragment>
      <div className='wrapper'>
        {/* //header style One*/}
        <Header />
        {/* //Sticky Header */}

        {/* //Mobile Header */}
        <section
          className={
            openSearchbar
              ? ' slider-row tabs-content pro-content home-two-sec'
              : 'tabs-content pro-content home-two-sec'
          }
        >
          <div className='container-fluid fluid-pad'>
            <div className='row img-con'>
              <div className='col-12 col-lg-12 btn-div-pp'>
                <a
                  onClick={() => {
                    window.history.back();
                  }}
                  href='/'
                  className='btn slider-btn-nnn swipe-to-top'
                >
                  <span className='pointer slider-btn-textt'>חזרה</span>
                  <img
                    alt=''
                    className='btn-img-m'
                    src='/assets/new-images/right.png'
                  />
                </a>
                <div className='icon-d-m'>
                  <span className='btn-extra-title-22'> המודעות שלי </span>
                  <img
                    alt=''
                    className='ml-2'
                    src='/assets/new-images/Group.png'
                  />
                </div>
              </div>
              <div className='col-12 slider-h-sc'>
                {/* Tab panes */}
                <div className='tab-content '>
                  <div
                    role='tabpanel'
                    className='tab-pane fade active show'
                    id='featured'
                  >
                    <div className='row search--contetn--wrapper'>
                      {final.length > 0 ? (
                        final.map((fin, index) => {
                          return (
                            <div key={index} className='inner-home-2-slide'>
                              <div className='product-adds home-2-product-adds home-3-product-adds'>
                                <article>
                                  <div className='.pro-thumb home-2-pro'>
                                    <Slider {...settings}>
                                      {fin.images.map((det01, index) => {
                                        return (
                                          <img
                                            key={index}
                                            className='img-fluid-text img-fluid '
                                            id=''
                                            src={det01}
                                            alt='Product-Image'
                                          />
                                        );
                                      })}
                                    </Slider>
                                  </div>
                                  <div className='pro-info'>
                                    <div className='pro-category '>
                                      <span
                                        className={
                                          width > 600
                                            ? ' home-two-t-1'
                                            : ' home-two-t-1-mobile'
                                        }
                                      >
                                        <span className='modStyle'>
                                          {fin.man} {''}
                                          {fin.mod}
                                        </span>
                                      </span>
                                    </div>
                                    <div className='title-2-main'>
                                      <span className='textCustom mr-2 homw-tow-t-2'>
                                        {fin.area}
                                      </span>
                                      <img
                                        alt=''
                                        className='w28'
                                        src='/assets/new-images/locationIcon.png'
                                      />
                                    </div>
                                    <div className='edit_del'>
                                      <div
                                        className='pointer del'
                                        onClick={() => onDelete(fin.key)}
                                      >
                                        <img
                                          alt=''
                                          className='w28'
                                          src='/assets/new-images/del_icon_mobile.png'
                                        />
                                      </div>
                                      <div
                                        className='pointer'
                                        onClick={() => onEdit(fin.key)}
                                      >
                                        <img
                                          alt=''
                                          className='w28'
                                          src='/assets/new-images/edit_icon_mobile.png'
                                        />
                                      </div>
                                    </div>
                                    {/* <div className='inner-home-2-slides'>
                                      <div className='flex-main-d'>
                                        <span className='shop-text-7'>
                                          תוספות לעיסקה:
                                        </span>

                                        <ul>
                                          <li className='shop-text-10'>
                                            מתחייב בבדיקה{' '}
                                          </li>
                                          <li className='shop-text-10'>
                                            מנוע ושילדה
                                          </li>
                                          <li className='shop-text-10'>
                                            אחריות יצרן
                                          </li>
                                        </ul>
                                      </div>
                                      <div className='flex-main-d-2'>
                                        <span className='shop-text-11'>
                                          החל מהחזר חודשי
                                        </span>
                                        <span className='shop-text-12'>
                                          הצעה למימון
                                        </span>
                                        <span className='shop-text-9'>
                                          של: 300 ש״ח{' '}
                                        </span>
                                        <span className='empty-spa'></span>
                                      </div>

                                      <div></div>
                                    </div> */}
                                    <div className='custom-grid  main-r-text00'>
                                      <div className='custom-grid-row'>
                                        <span className='custom-grid-col  '>
                                          <span className='textCustom-det-card mr-2'>
                                            ת.הילוכים:
                                          </span>
                                          <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                            {fin.gearBox}
                                          </span>
                                        </span>
                                        <span className='custom-grid-col'>
                                          <span className='textCustom-det-card mr-2'>
                                            סוג יד:
                                          </span>
                                          <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                            {fin.hand}
                                          </span>
                                        </span>
                                        <span className='custom-grid-col'>
                                          <span className='textCustom-det-card mr-2'>
                                            ק״מ:
                                          </span>
                                          <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                            {fin.km}
                                          </span>
                                        </span>
                                        <span className='custom-grid-col'>
                                          <span className='textCustom-det-card mr-2'>
                                            שנת יצור:
                                          </span>
                                          <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                            {fin.year}
                                          </span>
                                        </span>
                                        <span className='custom-grid-col'>
                                          <span className='textCustom-det-card mr-2'>
                                            מחיר:
                                          </span>
                                          <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                            {fin.price}₪
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className='flex-main-r '></div>
                                    <div className='home-2-btn mt-3'>
                                      <button
                                        onClick={() => moreDetails(fin.key)}
                                        to='/product-page'
                                        className='card-btn btn slider-btn-n swipe-to-top m-2'
                                      >
                                        <span className='slider-btn-text-22'>
                                          יצירת קשר
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                      ) : delay ? (
                        <h1
                          className='textCustom-title-2'
                          style={{ color: 'white' }}
                        >
                          לא נמצאו מודעות{' '}
                        </h1>
                      ) : (
                        <i
                          style={{ color: 'white' }}
                          className='fas fa-spinner fa-spin fa-4x'
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* //footer style One */}
        <Footer />
      </div>
      <div className='mobile-overlay' />
    </Fragment>
  );
};

export default MyAdds;
