import {
  MAIN_FILTER,
  OPEN_COMPOSE_MSG,
  OPEN_LABEL,
  OPEN_SEARCH_BAR,
  OPEN_MENULIST_POPUP,
  OPEN_SIDEBAR,
  WIDTH
} from '../actions/types';

const initialState = {
  openSidebar: false,
  opneCloseCompose: false,
  opneCloseLebal: false,
  opneSearchBar: false,
  openMenuListPop: false,
  width: 0,
  mainFilter: {
    carType: '',
    man: '',
    mod: '',
    smod: '',
    year: '',
    engCap: '',
    gearBox: '',
    area: '',
    hand: '',
  }
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPEN_COMPOSE_MSG:
      return {
        ...state,
        opneCloseCompose: payload,
      };
    case OPEN_LABEL:
      return {
        ...state,
        opneCloseLebal: payload,
      };
    case OPEN_SEARCH_BAR:
      return {
        ...state,
        opneSearchBar: payload,
      };

    case OPEN_MENULIST_POPUP:
      return {
        ...state,
        openMenuListPop: payload,
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        openSidebar: payload,
      };
      case WIDTH:
      return {
        ...state,
        width: payload,
      };
    case MAIN_FILTER:
      return {
        ...state,
        mainFilter: payload,
      };
    default:
      return state;
  }
}
