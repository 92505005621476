import React from 'react';
import { Fragment } from 'react';
import Slider from 'react-slick';
import Header from '../../layout/Header/index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../layout/Footer';
const HomeOne = () => {
  // const windowSize = useRef([window.innerWidth, window.innerHeight]);
  // const [width, setWidth] = useState(windowSize.current[0]);
  // const navigate = useNavigate();
  // const HandleCarType = (value) => {
  //   navigate(`/shop-search?carType=${value}`);
  // };
  const openSearchbar = useSelector((state) => state.settings.opneSearchBar);

  // const  = props;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 600,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <div className='wrapper'>
        {/* //header style One*/}
        <Header />

        {/* Revolution Layer Slider */}
        <div className={openSearchbar ? ' row slider-row2' : 'row slider-row2'}>
          <div className='col-12'>
            <div className='main-slider-h'>
              <Slider {...settings} className='custom-slider-home'>
                <div className='slide item slide-hei'>
                  <div className='img-fill slide-hei'>
                    <div
                      className=' infoo'
                      // style={{
                      //   backgroundImage:
                      //     'url()',
                      // }

                      // }
                    >
                      <div className='container'>
                        <div className='info_left mar-top-slider main-d-s'>
                          <span className='slider-d'>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top desktop-btn'
                            >
                              <span className='slider-btn-text'>
                                <img
                                  alt=''
                                  src='/assets/new-images/Vector.png'
                                />
                                לפרטים
                              </span>
                            </a>
                            <h2 className='h22'>
                              <span className='slider-text-1'>שירות על </span>
                              <span className='slider-text-2'>
                                - מקבלים רק אצלנו
                              </span>
                            </h2>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top mobile-btn'
                            >
                              <span className='slider-btn-text'>
                                <img
                                  alt=''
                                  src='/assets/new-images/Vector.png'
                                />
                                לפרטים
                              </span>
                            </a>
                          </span>
                          <img
                            alt=''
                            className='img-slide-n'
                            src='/assets/new-images/slider-1.png'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='slide item slide-hei'>
                  <div className='img-fill slide-hei'>
                    <div
                      className=' infoo'
                      // style={{
                      //   backgroundImage:
                      //     'url(/assets/new-images/slider-2.png)',
                      // }

                      // }
                    >
                      <div className='container'>
                        <div className='info_left mar-top-slider main-d-s'>
                          <span className='slider-d'>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top desktop-btn'
                            >
                              <span className='slider-btn-text'>
                                <img
                                  alt=''
                                  src='/assets/new-images/Vector.png'
                                />
                                לפרטים
                              </span>
                            </a>
                            <h2 className='h22'>
                              <span className='slider-text-1'>שירות על </span>
                              <span className='slider-text-2'>
                                - מקבלים רק אצלנו
                              </span>
                            </h2>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top mobile-btn'
                            >
                              <span className='slider-btn-text'>
                                <img src='/assets/new-images/Vector.png' />
                                לפרטים
                              </span>
                            </a>
                          </span>
                          <img
                            alt=''
                            className='img-slide-n'
                            src='/assets/new-images/slider-1.png'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='slide item slide-hei'>
                  <div className='img-fill slide-hei'>
                    <div
                      className=' infoo'
                      // style={{
                      //   backgroundImage:
                      //     'url(/assets/new-images/slider-2.png)',
                      // }

                      // }
                    >
                      <div className='container'>
                        <div className='info_left mar-top-slider main-d-s'>
                          <span className='slider-d'>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top desktop-btn'
                            >
                              <span className='slider-btn-text'>
                                <img
                                  alt=''
                                  src='/assets/new-images/Vector.png'
                                />
                                לפרטים
                              </span>
                            </a>
                            <h2 className='h22'>
                              <span className='slider-text-1'>שירות על </span>
                              <span className='slider-text-2'>
                                - מקבלים רק אצלנו
                              </span>
                            </h2>
                            <a
                              href='#'
                              className='btn slider-btn-n swipe-to-top mobile-btn'
                            >
                              <span className='slider-btn-text'>
                                <img
                                  alt=''
                                  src='/assets/new-images/Vector.png'
                                />
                                לפרטים
                              </span>
                            </a>
                          </span>
                          <img
                            alt=''
                            className='img-slide-n'
                            src='/assets/new-images/slider-1.png'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        {/* END REVOLUTION SLIDER */}
        {/* Banners */}
        {/* Tabs section */}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 '>
              {/* Tab panes */}
              <div className='tab-content'>
                <div
                  role='tabpanel'
                  className='tab-pane fade active show'
                  id='featured'
                >
                  {/* רשימת קטגוריות מסך ראשי */}
                  <div dir='rtl' className='row  home--gr--article'>
                    {/* פרטי */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=פרטי'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                פרטי{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=פרטי'>
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-3.png'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('פרטי')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* מסחרי */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=מסחרי'
                                //title-t
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                מסחרי{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=מסחרי'>
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-1.png'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('מסחרי')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* ג'יפים */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to="/shop-search?carType=ג'יפים"
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                ג'יפים{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to="/shop-search?carType=ג'יפים">
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-8.png'
                                alt='Product-Image'
                              />
                            </Link>
                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType("ג'יפים")}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* משאית */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=משאיות'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                משאיות{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=משאיות'>
                              {' '}
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-2.png'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('משאית')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* אופנועים */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=אופנועים'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                אופנועים{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=אופנועים'>
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-7.png'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div to='/shop' className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('אופנועים')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* קטנועים */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=קטנועים'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                קטנועים{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=קטנועים'>
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-4.jpg'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('קטנועים')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* טרקטורונים */}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=טרקטורונים'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                טרקטורונים{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=טרקטורונים'>
                              {' '}
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-5.png'
                                alt='Product-Image'
                              />
                            </Link>

                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() =>
                                        HandleCarType('טרקטורונים')
                                      }
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                    {/* מיוחדים*/}
                    <div className='col-6 col-xs-6 col-sm-6 col-md-3 col-lg-3'>
                      <div className='product'>
                        <article>
                          <div className='pro-info'>
                            <div className='pro-category '>
                              <Link
                                to='/shop-search?carType=מיוחדים'
                                className={'textCustom-title-1-cus '}
                              >
                                {' '}
                                מיוחדים{' '}
                              </Link>
                            </div>
                          </div>
                          <div className='pro-thumb'>
                            <Link to='/shop-search?carType=מיוחדים'>
                              <img
                                className='img-fluid img-c'
                                id=''
                                src='/assets/new-images/car-6.png'
                                alt='Product-Image'
                              />
                            </Link>
                            {/* <div className='pointer pro-hover-icons'>
                                <div className='icons'>
                                  <div className='pointer btn-light icon'>
                                    <i
                                      onClick={() => HandleCarType('מיוחדים')}
                                      className='fas fa-eye'
                                    />
                                  </div>
                                </div>
                              </div> */}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                  {/* 1st tab */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //footer style One */}
        <Footer />
      </div>
      <div className='mobile-overlay' />
    </Fragment>
  );
};

export default HomeOne;
