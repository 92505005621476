import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Fragment } from 'react';
import Slider from 'react-slick';
import Header from '../../layout/Header/index';
import { useSelector } from 'react-redux';
import Footer from '../../layout/Footer';
import { db } from '../../firebase/config';
import ReactWhatsapp from 'react-whatsapp';

const ProductPage = () => {
  const [carDetail, setCarDetail] = useState({});

  var images;

  const openSearchbar = useSelector((state) => state.settings.opneSearchBar);
  const width = useSelector((state) => state.settings.width);

  // const  = props;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const search = useLocation().search;
  const filteredData = async () => {
    const id = new URLSearchParams(search).get('id');
    //console.log(id, ': Product Id');

    try {
      const snap = await getDoc(doc(db, 'carDetail', id));
      setCarDetail({ ...snap.data() });
    } catch (error) {
      console.log(error);
    }
    //console.log('asdgsfdaadddddgh', carDetail);
    if (carDetail && carDetail.images) {
      images = carDetail.images.map((im, index) => {
        <img alt='' src={im} />;
      });
    }
  };

  useEffect(() => {
    filteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItems = (
    <div className='row  setting--gr'>
      <div className='col-12 col-lg-7'>
        <div className='slider-wrapper product-page-n'>
          {carDetail.images ? (
            <Slider {...settings} className='slider-for gr--add'>
              {' '}
              {carDetail.images.map((det01, index) => {
                return (
                  <img
                    key={index}
                    className='img-fluid '
                    id=''
                    src={det01}
                    alt='Product-Image'
                  />
                );
              })}{' '}
            </Slider>
          ) : (
            <img alt='תמונות' />
          )}
        </div>
        {/* <div className='messenger-main'>
          <div className='messenger-inner'>
            <img
              className='messenger-image'
              src='/assets/new-images/messenger.png'
            />
            <span className='messenger-text'>פנה במייל</span>
          </div>
          <div className='messenger-inner'>
            <img
              className='messenger-image'
              src='/assets/new-images/whatsapp.png'
            />
            <span className='messenger-text'>פנה בוואצ׳אפ</span>
          </div>
        </div> */}
        <div className='messenger-main'>
          <div className='messenger-inner'>
            <a className='pointer' href={`tel:${carDetail.phone}`}>
              {' '}
              <img
                alt=''
                className='messenger-image'
                src='/assets/new-images/phone-call.png'
              />
            </a>

            <span className='messenger-text'>
              צור קשר:
              <a className='pointer' href={`tel:${carDetail.phone}`}>
                {' '}
                {carDetail.phone}
              </a>
            </span>
          </div>
          <div className='messenger-inner Whatsapp'>
            <ReactWhatsapp
              number={`${carDetail.phone}`}
              message={`היי ${carDetail.fullName}  אני מעוניין ב${carDetail.man} ${carDetail.mod}`}
            >
              <img
                alt=''
                className='messenger-image-custom'
                src='/assets/new-images/whatsapp.png'
              />
              <span className='messenger-text-custom'>פנה בוואצ׳אפ</span>
            </ReactWhatsapp>
          </div>
        </div>
      </div>

      <div className='col-12 col-lg-5 pt-3'>
        <div className='row'>
          <div className='col-12 col-md-12'>
            {/* <div className='Product-page-d-1'>
              <div className='d-inner-1'>
                <span className='product-adds-detail-text-1'>הצעת מימון</span>
                <span className='product-adds-detail-text-2'>החזר חודשי</span>
                <span className='product-adds-detail-text-3'>
                  ₪865 <img src='/assets/new-images/edit.png' />
                </span>
              </div>
              <div className='vertical-1'></div>
              <div className='d-inner-1'>
                <span className='product-adds-detail-text-1'>הצעה הסוכנות</span>
                <span className='product-adds-detail-text-2'>מחיר</span>
                <span className='product-adds-detail-text-3'>
                  {carDetail.carType}
                </span>
              </div>
            </div> */}
            <div className='Product-page-d-2'>
              <div className='d-inner-2'>
                <span className='product-adds-detail-text-4'>
                  <a className='pointer' href={`tel:${carDetail.phone}`}>
                    {' '}
                    <strong className='product-adds-detail-text-5'>
                      צור קשר: {carDetail.phone}
                    </strong>{' '}
                  </a>
                </span>
                <img
                  className='d-inner-img'
                  src='/assets/new-images/phone.png'
                />
              </div>
              <div className='d-inner-2'>
                <span className='product-adds-detail-text-1'>
                  <strong className='product-adds-detail-text-5'>שם:</strong>{' '}
                  {carDetail.fullName}
                </span>
                <img
                  className='d-inner-img'
                  src='/assets/new-images/user.png'
                />
              </div>
            </div>
            <div className='Product-page-d-2-cus'>
              <div className='d-inner-2'>
                <span className='product-adds-detail-text-1'>
                  <strong className='product-adds-detail-text-5'>מחיר:</strong>{' '}
                  {carDetail.price}₪
                </span>
                <img
                  className='d-inner-img'
                  src='/assets/new-images/icon-sl.png'
                />
              </div>
            </div>
            <div className='Product-adds-page-d-3  thired--section'>
              <div className='d-inner-3-cus'>
                <div className='inner-two-text-desc'>
                  <span className='product-adds-detail-text-18'>
                    תיאור הרכב:
                  </span>
                  <div className='product-adds-detail-text-19'>
                    <span className='product-adds-detail-text-19-sub'>
                      {carDetail.desc}
                    </span>
                  </div>
                </div>
                <div>
                  {/* <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>לא זמין</span>
                  <span className='product-adds-detail-text-8'>צבע:</span>
                </div> */}
                </div>
                {/* <div>
                  <span className='product-adds-detail-text-7'>
                    תוספות לעיסקה:
                  </span>
                  <ul>
                    <li className='product-adds-detail-text-10'>
                      התחייבות לבדיקת גיר
                    </li>
                    <li className='product-adds-detail-text-10'>מנוע ושילדה</li>
                    <li className='product-adds-detail-text-10'>אחריות יצרן</li>
                  </ul>
                </div> 
                <div className='d-inner-3-2'>
                   <span className='product-adds-detail-text-11'>
                    הצעה למימון:
                  </span>
                  <ul>
                    <li className='product-adds-detail-text-12'>
                      החל מהחזר חודשי של:
                    </li>
                  </ul>
                  <span className='product-adds-detail-text-9'>300 ש״ח </span> 
                </div>*/}
              </div>
              <div className='vertical-2'></div>
              <div className='d-inner-4'>
                <span className='product-adds-detail-text-6'>פרטי הרכב</span>
                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {carDetail.year}
                  </span>
                  <span className='product-adds-detail-text-8'>שנה:</span>
                </div>
                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {carDetail.hand}
                  </span>
                  <span className='product-adds-detail-text-8'>יד:</span>
                </div>
                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {carDetail.km}
                  </span>
                  <span className='product-adds-detail-text-8'>
                    קילומטר נוכחי:
                  </span>
                </div>

                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {' '}
                    {carDetail.engCap}
                  </span>
                  <span className='product-adds-detail-text-8'>מנוע:</span>
                </div>

                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {carDetail.gearBox}
                  </span>
                  <span className='product-adds-detail-text-8'>
                    תיבת הילוכים:
                  </span>
                </div>
                <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>
                    {' '}
                    {carDetail.ownerShip}
                  </span>
                  <span className='product-adds-detail-text-8'>
                    בעלות נוכחית:
                  </span>
                </div>
                {/* <div className='inner-two-text'>
                  <span className='product-adds-detail-text-9'>לא זמין</span>
                  <span className='product-adds-detail-text-8'>
                    אגרת רישוי שנתית:
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className='wrapper'>
        {/* //header style One*/}
        <Header />
        {/* //Sticky Header */}

        {/* //Mobile Header */}

        <div
          className={
            openSearchbar
              ? 'slider-row pro-content2  page-content product-page product-page-one'
              : ' pro-content2 page-content product-page product-page-one'
          }
        >
          <div className='container-fluid product--page--gr'>
            <div className='row'>
              <div className='col-12 col-lg-12 btn-div-p'>
                <a
                  onClick={() => {
                    window.history.back();
                  }}
                  className='btn slider-btn-nn swipe-to-top'
                >
                  <span className='slider-btn-textt'> חזרה לרכבים</span>
                  <img
                    className='btn-img-m'
                    src='/assets/new-images/right.png'
                  />
                </a>
                <div className='icon-d-m'>
                  <span
                    className={
                      width > 600
                        ? 'btn-extra-title-main-home'
                        : 'btn-extra-title-main-home-mobile'
                    }
                  >
                    <span
                      className={
                        width > 600 ? 'modStyle-home' : 'modStyle-home-mobile '
                      }
                    >
                      {carDetail.man} {''}
                      {carDetail.mod}
                    </span>
                    <img src='/assets/new-images/Group.png' />
                  </span>
                </div>
              </div>
              <div className=' col-12 col-lg-12 title-2-pro'>
                <span className='textCustomArea mr-2 mt-1  homw-tow-t-2'>
                  {carDetail.area}
                </span>
                <img
                  className='w28'
                  src='/assets/new-images/locationIcon.png'
                />
              </div>
            </div>
            {carDetail ? listItems : 'nothing'}
          </div>
        </div>
        {/* //footer style One */}
        <Footer />
      </div>
      <div className='mobile-overlay' />
    </Fragment>
  );
};

export default ProductPage;
