import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// old key for testing fire bace

//const sfirebaseConfig = {
  //   //3rd firebase

  // apiKey: 'AIzaSyCgqVNj_EC8K_q2VVrfnhPq80jn8nfH_Sc',
  // authDomain: 'carsssss-691eb.firebaseapp.com',
  // projectId: 'carsssss-691eb',
  // storageBucket: 'carsssss-691eb.appspot.com',
  // messagingSenderId: '288947835331',
  // appId: '1:288947835331:web:9983f61c2121bf5ae56d76',

  //   //2nd firebase
  // apiKey: 'AIzaSyAo5IkAOWlv4Ifwtgz-Eu0yIYvQzcdVeZM',
  // authDomain: 'carsdetails-ec47d.firebaseapp.com',
  // projectId: 'carsdetails-ec47d',
  // storageBucket: 'carsdetails-ec47d.appspot.com',
  // messagingSenderId: '346518366947',
  // appId: '1:346518366947:web:eaefcba37d7561328173d6',

  // apiKey: "AIzaSyCgxPfe2txxt_03INfGhkDyx1-RvVEHxyw",
  // authDomain: "cars-c30ad.firebaseapp.com",
  // projectId: "cars-c30ad",
  // storageBucket: "cars-c30ad.appspot.com",
  // messagingSenderId: "541101798259",
  // appId: "1:541101798259:web:28feac40dc5a334166cb06"
//};

//lsat
const firebaseConfig = {
  apiKey: 'AIzaSyCYb88It13ZgiZw-aRhV1n61xdf5zUNEXo',
  authDomain: 'halalit-cars-210ea.firebaseapp.com',
  projectId: 'halalit-cars-210ea',
  storageBucket: 'halalit-cars-210ea.appspot.com',
  messagingSenderId: '875424730512',
  appId: '1:875424730512:web:bc8035edcc459ee63f7715',
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
