import './EditProduct.css';
import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Card, Container, Row, Col } from 'react-bootstrap';
// import cars_filter_data from '../../cars_filter_data.json';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebase/config';
import { storage } from '../../firebase/config';
import { UserAuth } from '../../firebase/Auth';
import Header from '../../layout/Header/index';
import Footer from '../../layout/Footer/index';
import { toast, ToastContainer } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { v4 } from 'uuid';
import {
  getStorage,
  ref,
  listAll,
  uploadBytesResumable,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  collection,
  updateDoc,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';

const EditAdds = () => {
  let count = 0;

  const { user } = UserAuth();
  const [catType, setCatType] = useState([]);
  //const [data, setData] = useState(cars_filter_data);
  const [show, setShow] = useState(true);

  const [carType, setCarType] = useState([]);
  const [mans, setMans] = useState([]);
  const [mods, setMods] = useState([]);
  const [smods, setSMods] = useState([]);
  const [years, setYears] = useState([]);
  const [engCaps, setEngCaps] = useState([]);
  const [gearBoxes, setGearBoxes] = useState([]);
  const [area, setArea] = useState([]);
  const [hand, setHand] = useState([]);
  const [ownerShip, setOwnerShip] = useState([]);
  const [price, setPrice] = useState([]);
  const [km, setKm] = useState([]);
  const [imageUpload, setImageUpload] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [desc, setDesc] = useState([]);
  const [fullName, setFullName] = useState([]);
  const [phone, setPhone] = useState([]);

  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [oldID, setOldID] = useState();

  const imageListRef = ref(storage, 'images/');
  const inputRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const now = new Date().toLocaleTimeString();
  let [time, setTime] = React.useState(now);
  const [carTypes, setCarTypes] = useState([
    'פרטי',
    'מסחרי',
    "ג'יפים",
    'משאיות',
    'אופנועים',
    'קטנועים',
    'טרקטורונים',
    'מיוחדים',
  ]);
  // const [customErrors, setErrors] = useState({
  //   carType: undefined,
  //   man: undefined,
  //   mod: undefined,
  //   smod: undefined,
  // });
  const [values, setValues] = useState({
    carType: '',
    man: '',
    mod: '',
    smod: '',
    year: '',
    price: '',
    engCap: '',
    gearBox: '',
    km: '',
    ownerShip: '',
    desc: '',
    fullName: '',
    phone: '',
    hand: '',
    area: '',
  });

  // Fetch listing to edit
  useEffect(() => {
    //setLoading(true);
    const fetchListing = async () => {
      //let paramKey = params.addsKey.slice(1);
      setOldID(params.addsKey);
      const docRef = doc(db, 'carDetail', params.addsKey);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //setFi(docSnap.data());
        // console.log(docSnap.data());
        setCarType(docSnap.data().carType);
        setMans(docSnap.data().man);
        setMods(docSnap.data().mod);
        setSMods(docSnap.data().smod);
        setYears(docSnap.data().year);
        setEngCaps(docSnap.data().engCap);
        setGearBoxes(docSnap.data().gearBox);
        setArea(docSnap.data().area);
        setHand(docSnap.data().hand);
        setOwnerShip(docSnap.data().ownerShip);
        let tempPrice = docSnap.data().price;
        let tempKm = docSnap.data().km;
        setPrice(tempPrice.replace(',', ''));
        setKm(tempKm.replace(',', ''));
        setImageUrls(docSnap.data().images);
        setDesc(docSnap.data().desc);
        setFullName(docSnap.data().fullName);
        setPhone(docSnap.data().phone);

        // setValues({
        //   ...docSnap.data(),
        //   carType: docSnap.data().carType,
        //   man: docSnap.data().man,
        //   mod: docSnap.data().mod,
        //   smod: docSnap.data().smod,
        //   year: docSnap.data().year,
        //   price: docSnap.data().price,
        //   engCap: docSnap.data().engCap,
        //   gearBox: docSnap.data().gearBox,
        //   km: docSnap.data().km,
        //   ownerShip: docSnap.data().ownerShip,
        //   desc: docSnap.data().desc,
        //   fullName: docSnap.data().fullName,
        //   phone: docSnap.data().phone,
        //   hand: docSnap.data().hand,
        //   area: docSnap.data().area,
        // });

        setLoading(false);
      } else {
        toast.error(' זמנית לא ניתן לערוך');
        navigate('/');
      }
    };

    fetchListing();
  }, [params.addsKey, navigate]);

  // useEffect(() => {
  //   let array = [];
  //   for (let i in data) {
  //     array.push(data[i]);
  //   }
  //   setData(array);
  // }, []);

  const formik = useFormik({
    initialValues: {
      carType: '',
      man: '',
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    },

    enableReinitialize: true,
  });

  const changeHandler = async (e) => {
    let newImage = [];
    for (let i = 0; i < e.target.files.length < 5; i++) {
      let t = e.target.files[i];
      //newImage['id'] = v4();
      if (t) {
        newImage[i] = t;

        newImage[i].id = await v4();
        // await setImageUpload((prevState) => [...prevState, newImage]);
        //setImageUpload([newImage]);
      } else break;
    }
    //if (newImage) setImageUpload([newImage]);
    uploadImage(...[newImage]);
    //let x = imageUpload;

    //await uploadImage();
  };

  const uploadImage = async (newImage) => {
    if (newImage === []) return <h1>בבקשה להעלות תמונה</h1>;
    // const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
    const imageRef = newImage.map((img) =>
      ref(storage, `images/${img.name + img.id}`)
    );

    for (let i = 0; i < newImage.length; i++) {
      setImgLoading(true);
      const byte = await uploadBytes(imageRef[i], newImage[i]);
      setImgLoading(false);
      toast.success('התמונה עלתה בהצלחה');
      const url = await getDownloadURL(byte.ref);
      await setImageUrls((prev) => [...prev, url]);
    }
  };

  const resetAll = () => {
    setLoading(false);
    setValues({
      carType: '',
      man: '',
      mod: '',
      smod: '',
      year: '',
      price: '',
      engCap: '',
      gearBox: '',
      km: '',
      ownerShip: '',
      desc: '',
      fullName: '',
      phone: '',
      hand: '',
      area: '',
    });
    setArea([]);
    setPrice([]);
    setKm([]);
    setHand([]);
    setOwnerShip([]);
    setDesc([]);
    setFullName([]);
    setPhone([]);
    setImageUrls([]);
    setImageUpload([]);
    //inputRef.current.value = null;
  };

  const handleSubmitForm = async (e) => {
    setLoading(true);

    // done in this way, becuse is no time
    // the on submit func in formik nor work, i do what i can, sorry.
    let nf = new Intl.NumberFormat();

    let tempData = {
      userId: user.uid,
      carType: carType,
      man: mans,
      mod: mods,
      smod: smods,
      year: years,
      engCap: engCaps,
      gearBox: gearBoxes,
      hand: hand,
      area: area,
      km: nf.format(km),
      desc: desc,
      ownerShip: ownerShip,
      price: nf.format(price),
      fullName: fullName,
      phone: phone,
      images: imageUrls,
    };
    try {
      const docRef = await addDoc(collection(db, 'carDetail'), tempData);
      setLoading(false);
      //resetForm();
      toast.success('המידע נשמר');
    } catch (e) {
      console.log('error => e: ' + e);
      setLoading(false);
      toast.error('המידע לא נשמר');
      navigate('/');
    }
    deleteDoc(doc(db, 'carDetail', oldID));
    navigate('/MyAdds');
    resetAll();
    setLoading(false);
    //onSubmit={formik.handleSubmit}
  };

  return (
    <>
      <Header />
      <section className='product_detail_Main_wrapper'>
        <Form className='text-center'>
          <div className='container addPro'>
            <div className='col-lg-9'>
              <a
                onClick={() => {
                  window.history.back();
                }}
                className='btn slider-btn-mmm swipe-to-top'
              >
                <span className='slider-btn-textt'>חזרה</span>
                <img className='btn-img-m' src='/assets/new-images/right.png' />
              </a>
            </div>
            <div className='row justify-content-center'>
              <div className='textCustom-title-1 color-red'>עריכת מודעה</div>
            </div>
            <div className='textCustom-title-1'>{`${mans} ${mods}`}</div>
            <div className='textCustom-title-4'>{`${smods}`}</div>
            <hr className='hr' />

            <div className='one' id='myDIV'>
              <div className='row justify-content-center mt-3 '></div>
            </div>

            <div className='row justify-content-center hello_world'>
              <div className='rtl-main banners-content col-lg-9 col-md-12 col-sm-12'>
                {carType !== '' ? (
                  <div>
                    <label className='ui horizontal label'>סוג הרכב </label>
                    <div className=' ui input large p-0'>
                      <div
                        name='carType'
                        className='pointer textCustom form-control'
                      >
                        {carType}
                      </div>{' '}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {mods !== '' ? (
                  <div>
                    <label className='ui horizontal label'>בחר יצרן</label>
                    <div name='man' className='pointer textCustom form-control'>
                      {mans}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {mods !== '' ? (
                  <div>
                    <label className='ui horizontal label'>בחר דגם</label>
                    <div name='mod' className='pointer textCustom form-control'>
                      {mods}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {smods !== '' &&
                carType !== 'אופנועים' &&
                carType !== 'משאיות' ? (
                  <div>
                    <label className='ui horizontal label'>בחר תת דגם</label>
                    <div
                      name='smod'
                      className='pointer textCustom form-control'
                    >
                      {smods}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {smods !== '' ? (
                  <div>
                    <label className='ui horizontal label'>בחר שנה</label>
                    <div
                      name='year'
                      className='pointer textCustom form-control'
                    >
                      {years}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {smods !== '' ? (
                  <div>
                    <label className='ui horizontal label '>בחר נפח מנוע</label>
                    <div
                      name='engCap'
                      className='pointer textCustom form-control'
                    >
                      {engCaps}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {smods !== '' ? (
                  <div>
                    <label className='ui horizontal label'>
                      בחר סוג תיבת הילוכים
                    </label>
                    <div
                      name='gearBox'
                      className='pointer textCustom form-control p-0'
                    >
                      {gearBoxes}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {smods !== '' ? (
                  <div>
                    <label className='ui horizontal label '>אזור בארץ </label>
                    <div
                      name='area'
                      className='pointer textCustom form-control p-0'
                    >
                      {area}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                {hand !== '' ? (
                  <div>
                    {' '}
                    <label className='ui horizontal label '>יד</label>
                    <div
                      name='hand'
                      className='pointer textCustom form-control p-0'
                    >
                      {hand}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                <hr className='hr' />
                <hr className='hr' />
                <div className='textCustom-title color-red'>
                  שדות הניתנים לעריכה
                </div>
                <hr className='hr' />

                <label className='ui horizontal label '>בעלות נוכחית</label>
                <input
                  type='text'
                  id='ownerShip'
                  name='ownerShip'
                  required
                  onChange={(e) => setOwnerShip(e.target.value)}
                  value={ownerShip}
                  className='textCustom form-control input'
                />
                <label className='ui horizontal label'>מחיר</label>
                <input
                  min='500'
                  max='75000000'
                  required
                  type='number'
                  id='price'
                  name='price'
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  className='textCustom form-control input'
                />
                <label className='ui horizontal label'>הקלד ק”מ</label>
                <div className='ui input large p-0'>
                  <input
                    type='text'
                    min='0'
                    required
                    id='km'
                    name='km'
                    onChange={(e) => setKm(e.target.value)}
                    value={km}
                    className='textCustom form-control input'
                  />
                </div>
                <label> תיאור המוצר </label>
                <textarea
                  onChange={(e) => setDesc(e.target.value)}
                  className='textCustom form-control '
                  value={desc}
                  id='exampleFormControlTextarea1'
                  rows='3'
                  required
                ></textarea>
                <label>שם מלא</label>
                <input
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className='textCustom form-control input'
                />
                <label>מספר טלפון</label>
                <input
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className='textCustom form-control input'
                />
                <br />
                <Button
                  //type='submit'
                  variant='primary'
                  onClick={handleSubmitForm}
                  className='addButton'
                  disabled={submit}
                  size='lg'
                >
                  {loading ? (
                    <ClipLoader size={25} color='white' loading />
                  ) : (
                    'עדכן מודעה'
                  )}
                </Button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </Form>
      </section>
      <Footer />
    </>
  );
};
export default EditAdds;
