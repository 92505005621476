import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Slider from 'react-slick';
import Header from '../../layout/Header/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../layout/Footer';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

const ShopOne = () => {
  const [final, setFinal] = useState([]);

  const [delay, setDelay] = useState(false);
  let results = [];
  //let [userId, setUserId] = useState('');
  const [details, setDetails] = useState([]);
  const navigate = useNavigate();
  const [run, setRun] = useState(false);

  const widthh = useSelector((state) => state.settings.width);
  const [width, setWidth] = useState(widthh);

  const openSearchbar = useSelector((state) => state.settings.opneSearchBar);

  let carType = '';
  let man = '';
  let mod = '';
  let smod = '';
  let year = '';
  let engCap = '';
  let gearBox = '';
  let area = '';
  let price = '';
  // const  = props;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    setWidth(widthh);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthh]);

  let mainFilter = useSelector((state) => state.settings.mainFilter);

  const myFilter = (element) => {
    if (element.carType === mainFilter.carType && mainFilter.man === '')
      // all the note is for the code above
      // check if -> carType && man from filter fit the data
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      mainFilter.mod === '' &&
      mainFilter.year === ''
    )
      // check else if -> carType && man from filter fit the data
      return true;
    else if (
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod === mainFilter.mod &&
        mainFilter.smod === '' &&
        mainFilter.year === ' ') ||
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        mainFilter.mod === '' &&
        mainFilter.smod === '' &&
        element.year === String(mainFilter.year) &&
        mainFilter.engCap === '')
    )
      // its for 2 cases *above
      //1. element.smod === mainFilter.smod
      // 2. element.smod === "" // for case the user don't enter smod & skip to year.
      // from now will be 2 caese for for each 'smod' becuse the problem above.
      return true;
    else if (
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod === mainFilter.mod &&
        element.smod === mainFilter.smod &&
        mainFilter.year === '') ||
      (element.carType === mainFilter.carType &&
        element.man === mainFilter.man &&
        element.mod !== '' &&
        mainFilter.smod === '' &&
        element.year === String(mainFilter.year) &&
        mainFilter.engCap === '')
    )
      // its for 2 cases *above
      //1. element.smod === mainFilter.smod
      // 2. element.smod === "" // for case the user don't enter smod & skip to year.
      // from now will be 2 caese for for each 'smod' becuse the problem above.
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      element.mod === mainFilter.mod &&
      element.smod === mainFilter.smod &&
      element.year === String(mainFilter.year) &&
      String(mainFilter.engCap) === ''
    )
      // for case both smod & year not -> ""
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      mainFilter.gearBox === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      mainFilter.area === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      element.area === mainFilter.area &&
      String(mainFilter.hand) === ''
    )
      return true;
    else if (
      element.carType === mainFilter.carType &&
      element.man === mainFilter.man &&
      (element.mod === mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod === mainFilter.smod || mainFilter.smod === '') &&
      element.year === String(mainFilter.year) &&
      element.engCap === String(mainFilter.engCap) &&
      element.gearBox === mainFilter.gearBox &&
      element.area === mainFilter.area &&
      element.hand === String(mainFilter.hand)
    )
      return true;
    else if (
      element.carType !== mainFilter.carType &&
      element.man !== mainFilter.man &&
      (element.mod !== mainFilter.mod || mainFilter.mod !== '') &&
      (element.smod !== mainFilter.smod || mainFilter.smod !== '') &&
      element.year !== String(mainFilter.year) &&
      element.engCap !== String(mainFilter.engCap) &&
      element.gearBox !== mainFilter.gearBox &&
      element.area !== mainFilter.area &&
      element.hand !== String(mainFilter.hand)
    )
      return false;
    else return false;
  };

  useEffect(() => {
    setDelay(false);
    // Iterate through each element
    details.forEach((element) => {
      // Push in the filteredArr if
      // it returns false
      if (myFilter(element) === true) {
        results.push(element);
      }
    });

    setFinal(results);
    setTimeout(() => {
      setDelay(true);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainFilter]);

  // Not active - for later.
  // const getSearch = async () => {
  //   let local = [];
  //   const q = query(collection(db, 'carDetail'), where('man', '==', man));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     local.push({
  //       ...doc.data(),
  //       key: doc.id,
  //     });
  //   });
  //   //setFinalSearch(local);
  //   setFinal(local);
  // };
  // const search = useLocation().search;
  const search = useLocation().search;

  useEffect(() => {
    setDelay(false);
    // get type from the url    <- send from search | main screen
    carType = new URLSearchParams(search).get('carType');
    man = new URLSearchParams(search).get('man');
    mod = new URLSearchParams(search).get('mod');
    smod = new URLSearchParams(search).get('smod');
    year = new URLSearchParams(search).get('year');
    engCap = new URLSearchParams(search).get('engCap');
    gearBox = new URLSearchParams(search).get('gearBox');
    area = new URLSearchParams(search).get('area');

    const local = [];
    const filteredData = async () => {
      const querySnapshot = await getDocs(collection(db, 'carDetail'));
      querySnapshot.forEach((doc) => {
        local.push({
          ...doc.data(),
          key: doc.id,
        });
      });
      setDetails(local);
      results = local.filter((element) => {
        return (
          element.carType === carType ||
          element.man === man ||
          element.mod === mod ||
          element.smod === smod ||
          element.smod === smod ||
          element.year === year ||
          element.engCap === engCap ||
          element.gearBox === gearBox ||
          element.area === area ||
          element.price <= price
        );
      });

      setFinal([...results]);
      if (final) {
        setRun(true);
      }
    };
    filteredData();
    //const userId01 = JSON.stringify(localStorage.getItem('userId'));
    //setUserId(userId01);
    setTimeout(() => {
      setDelay(true);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run, carType]);

  const moreDetails = (id) => {
    navigate(`/product-page?id=${id}`);
  };

  return (
    <Fragment>
      <div className='wrapper'>
        {/* //header style One*/}
        <Header />

        {/* //Sticky Header */}

        {/* //Mobile Header */}
        <section
          className={
            openSearchbar
              ? ' slider-row tabs-content pro-content home-two-sec'
              : 'tabs-content pro-content home-two-sec'
          }
        >
          <div className='container-fluid fluid-pad'>
            <div className='row img-con'>
              <div className='col-12 col-lg-12 btn-div-pp'>
                <a
                  onClick={() => {
                    window.history.back();
                  }}
                  className='btn slider-btn-nnn swipe-to-top'
                >
                  <span className='slider-btn-textt'>חזרה</span>
                  <img
                    alt=''
                    className='btn-img-m'
                    src='/assets/new-images/right.png'
                  />
                </a>
                <div className='icon-d-m'>
                  <span className='btn-extra-title-2'>
                    <span className='modStyle-cus'> תוצאות חיפוש: </span>{' '}
                    <img
                      alt=''
                      className='ml-2'
                      src='/assets/new-images/Group.png'
                    />
                  </span>
                </div>
                {/* <div className='icon-d-m'>
                  <span className='btn-extra-title-2'> תוצאות חיפוש: </span>
                  <img alt="" className='ml-2' src='/assets/new-images/Group.png' />
                </div> */}
              </div>
              <div className={width > 600 ? 'col-12 slider-h-sc' : 'col-12'}>
                {/* Tab panes */}
                <div className='tab-content '>
                  <div
                    role='tabpanel'
                    className='tab-pane fade active show'
                    id='featured'
                  >
                    <div className='row search--contetn--wrapper'>
                      {final.length > 0 ? (
                        final.map((fin, index) => {
                          return (
                            <div key={index} className='inner-home-2-slide'>
                              <div className='product-adds home-2-product-adds home-3-product-adds'>
                                <article>
                                  <div
                                    className={
                                      width > 600
                                        ? 'pro-thumb home-2-pro '
                                        : 'pro-thumb home-2-pro-mobile-main '
                                    }
                                  >
                                    <Slider {...settings}>
                                      {fin.images.map((det01, index) => {
                                        return (
                                          <img
                                            key={index}
                                            className='img-fluid-text img-fluid '
                                            id=''
                                            src={det01}
                                            alt='Product-Image'
                                          />
                                        );
                                      })}
                                    </Slider>
                                  </div>
                                  <div
                                    className='pointer'
                                    onClick={() => moreDetails(fin.key)}
                                  >
                                    {' '}
                                    <div className='pro-info'>
                                      <div className='pro-category '>
                                        <span
                                          className={
                                            width > 600
                                              ? ' home-two-t-1'
                                              : ' home-two-t-1-mobile'
                                          }
                                        >
                                          <span
                                            className={
                                              width > 600
                                                ? 'modStyle'
                                                : 'modStyle-cus-0'
                                            }
                                          >
                                            {fin.man} {''}
                                            {fin.mod}
                                          </span>
                                        </span>
                                      </div>
                                      <span className='textCustom-det-card-sub-1'>
                                        {fin.year}
                                      </span>
                                      <div className='title-2-main'>
                                        <span className='textCustomArea mr-2 homw-tow-t-2'>
                                          {fin.area}
                                        </span>
                                        <img
                                          alt=''
                                          className='w28'
                                          src='/assets/new-images/locationIcon.png'
                                        />
                                      </div>
                                      <div className='custom-grid-main  main-r-text00'>
                                        <div className='custom-grid-row'>
                                          <span className='custom-grid-col  '>
                                            <span className='textCustom-det-card mr-2'>
                                              ת.הילוכים:
                                            </span>
                                            <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                              {fin.gearBox}
                                            </span>
                                          </span>
                                          <span className='custom-grid-col'>
                                            <span className='textCustom-det-card mr-2'>
                                              סוג יד:
                                            </span>
                                            <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                              {fin.hand}
                                            </span>
                                          </span>
                                          <span className='custom-grid-col'>
                                            <span className='textCustom-det-card mr-2'>
                                              ק״מ:
                                            </span>
                                            <span className='custom-grid-col-sub textCustom-det-card-sub'>
                                              {fin.km}
                                            </span>
                                          </span>
                                          <span className='custom-grid-col'>
                                            <span
                                              className={
                                                width > 600
                                                  ? 'textCustom-det-card-sub-2 mr-2 '
                                                  : 'textCustom-det-card-sub-2-mobile mr-2 '
                                              }
                                            >
                                              מחיר:
                                            </span>
                                            <span
                                              className={
                                                width > 600
                                                  ? 'custom-grid-col-sub textCustom-det-card-sub-2'
                                                  : 'custom-grid-col-sub textCustom-det-card-sub-2-mobile'
                                              }
                                            >
                                              {fin.price}₪
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className='home-2-btn mt-3'>
                                        <button
                                          onClick={() => moreDetails(fin.key)}
                                          to='/product-page'
                                          className='card-btn btn slider-btn-n swipe-to-top m-2'
                                        >
                                          <span className='slider-btn-text-22'>
                                            יצירת קשר
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          );
                        })
                      ) : delay ? (
                        <h1
                          className='textCustom-title-2'
                          style={{ color: 'white' }}
                        >
                          לא נמצאו מודעות{' '}
                        </h1>
                      ) : (
                        <i
                          style={{ color: 'white' }}
                          className='fas fa-spinner fa-spin fa-4x'
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* //footer style One */}
        <Footer />
      </div>
      <div className='mobile-overlay' />
    </Fragment>
  );
};

export default ShopOne;
{
  /* <div className='pro-hover-icons'>
    <div className='icons'>
      <a href='#' className=''>
        <img alt="" src='/assets/new-images/heart.png' />{' '}
        <span className='hover-img-title'>
          אסור לפספס
        </span>
      </a>
    </div>
    </div> */
}
