import React from 'react';
import { Fragment } from 'react';

const Footer = () => {
  return (
    <Fragment>
      <footer
        id='footerOne'
        className='footer-area footer-one footer-desktop footer-web'
      >
        <div className='container-fluid p-0'>
          <div className='copyright-content'>
            <div className='gr--margin'>
              <div className='row align-items-center'>
                <div className='col-12 col-md-6'>
                  <div className='footer-info'>
                    <span className='foot-3'>חללית</span>
                    <strong className='foot-33'>קאר</strong>
                    <img alt='' src='/assets/new-images/footer.png' />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <ul className='socials'>
                    <li>
                      <a href='#' className='footer-l'>
                        רישום לניוזלטר
                      </a>
                    </li>
                    <li>
                      <a href='#' className='footer-l'>
                        פרסם מודעה
                      </a>
                    </li>
                    <li>
                      <a href='#' className='footer-l'>
                        בדיקת מימון
                      </a>
                    </li>
                    <li>
                      <a href='#' className='footer-l'>
                        חיפוש רכב
                      </a>
                      <img
                        className='footer-img-n'
                        src='/assets/new-images/mini.png'
                      />
                    </li>
                    <li>
                      <div className='vertical'></div>
                    </li>
                    <li>
                      <a href='#' className='footer-l'>
                        כל הזכויות שמורות
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer
        id='footerOne'
        className='footer-area footer-one footer-desktop footer-mobile'
      >
        <div className='container-fluid p-0'>
          <div className='copyright-content'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-12 bg-footer '>
                  <a href='#' className='btn slider-btn-n-f swipe-to-top'>
                    <span className='slider-btn-text-f'>
                      <img src='/assets/new-images/footer-btn-2.png' />
                      מימון לרכב
                    </span>
                  </a>
                  <a href='#' className='btn slider-btn-n-f-2 swipe-to-top'>
                    <span className='slider-btn-text'>
                      <img src='/assets/new-images/footer-btn.png' />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
