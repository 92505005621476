import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/config';
import { UserAuth } from '../../firebase/Auth';
import { toast, ToastContainer } from 'react-toastify';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

import './SignUp.css';
const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [password, setPassword] = useState('');
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [width, setWidth] = useState(windowSize.current[0]);

  const { signUp } = UserAuth();

  const navigate = useNavigate();
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  useEffect(() => {
    setWidth(windowSize.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      try {
        await signUp(auth, email, password).then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          //console.log('user', user.uid);
          localStorage.setItem('userId', user.uid);

          // ...
        });
        // toast.success("Account created successfuly");
        navigate('/');
      } catch (e) {
        toast.error(`קיים שגיאה ב : ${e}`);
      }
    } else {
      toast.error('חייב להכניס מייל תקין!');
    }
  };

  return (
    <div className={width > 500 ? 'Signup' : 'SignupMobile'}>
      <div className='container signup_container'>
        <form onSubmit={handleSubmit} className='signup_form'>
          <div className='row' dir='rtl'>
            <div>
              <h2 className='mb-4 textCustom-title-1'>הירשם</h2>
            </div>
          </div>
          <div className='row'>
            <div className='textCustomLogin col'>
              <label>שם</label>
              <input
                type='text'
                className='textCustom text-r form-control'
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
          </div>
          <div className='row'>
            <div className='textCustomLogin col'>
              <label>אימייל</label>
              <input
                type='email'
                className='textCustom text-r form-control'
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <div className='row'>
            <div className='textCustomLogin col'>
              <label>סיסמה</label>
              <input
                type={passwordType}
                required
                className='textCustom text-r form-control'
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <button className=' btn-t ' onClick={togglePassword}>
                <Icon icon={eye} size={15} />
              </button>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col'>
              <button
                type='submit'
                className='textCustomLogin signup_btn mb-4'
                onSubmit={handleSubmit}
              >
                הירשם <ToastContainer />
              </button>
            </div>
          </div>
          <Link to='/login' className='textCustomLogin registerLink'>
            התחבר במקום
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Signup;
