import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { settings } from '../../components/Slider/sliderSettings';
const CarCardUp = ({ det, index }) => {
  const navigate = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [width, setWidth] = useState(windowSize.current[0]);
  //const uid = carDetial.id

  const moreDetails = (id) => {
    // event.preventDefault();
    navigate(`/product-page?id=${id}`);
  };
  useEffect(() => {
    setWidth(windowSize.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);
  return (
    <article>
      <div
        className={
          width > 600 ? 'pro-thumb home-2-pro ' : 'pro-thumb home-2-pro-mobile '
        }
      >
        <Slider {...settings}>
          {det &&
            det.images &&
            det.images.map((det01, index) => {
              return (
                <img
                  key={index}
                  className='img-fluid-text img-fluid '
                  id=''
                  src={det01}
                  alt='Product-Image'
                />
              );
            })}
        </Slider>
      </div>
      <div className='pointer' onClick={() => moreDetails(det.key)}>
        <div className='pro-info'>
          <div className='pro-category '>
            <span
              className={width > 600 ? 'home-two-t-1' : ' home-two-t-1-mobile'}
            >
              <span className={width > 600 ? 'modStyle' : 'modStyle-cus-0'}>
                {det.man} {''}
                {det.mod}
              </span>
            </span>
          </div>
          <span className='textCustom-det-card-sub-1'>{det.year}</span>
          <div className='title-2-main'>
            <span className='textCustomArea mr-2 homw-tow-t-2'>{det.area}</span>
            <img
              alt=''
              className='w28'
              src='/assets/new-images/locationIcon.png'
            />
          </div>
          <div className='custom-grid-main  main-r-text00'>
            <div className='custom-grid-row'>
              <span className='custom-grid-col  '>
                <span className='textCustom-det-card mr-2'>ת.הילוכים:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.gearBox}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span className='textCustom-det-card mr-2'>סוג יד:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.hand}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span className='textCustom-det-card mr-2'>ק״מ:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.km}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span
                  className={
                    width > 600
                      ? 'textCustom-det-card-sub-2 mr-2 '
                      : 'textCustom-det-card-sub-2-mobile mr-2 '
                  }
                >
                  מחיר:
                </span>
                <span
                  className={
                    width > 600
                      ? 'custom-grid-col-sub textCustom-det-card-sub-2'
                      : 'custom-grid-col-sub textCustom-det-card-sub-2-mobile'
                  }
                >
                  {det.price}₪
                </span>
              </span>
            </div>
          </div>

          <div className='home-2-btn mt-3'>
            {/* <Link */}
            <button
              onClick={() => moreDetails(det.key)}
              to='/product-page'
              className='card-btn btn slider-btn-n swipe-to-top mb-2 '
            >
              <span className='slider-btn-text-22'>יצירת קשר</span>
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </article>
  );
};

export default CarCardUp;

{
  /* <div
className="w-100 br-15px bc-white pb-20px"
style={{ boxShadow: "5px 30px 18px #888888" }}
key={carDetial.id}
onClick={() => {
 navigate(`/carDetail/${uid}`)
}}
> */
}
{
  /* <h3 className="fw-700 fs-20px ff-rubik ">{carDetial?.man.concat(" ").concat(carDetial?.mod)}</h3>
      <span className="d-flex fs-16px">
        <span className="material-icons">pin_drop</span>
         <span>{carDetial.ownership}</span> 
      </span>
 
          <div className="d-flex g-8px jc-space-around fw-warp">
            <span className="d-flex g-2px fd-column">
              <span>year</span>
            </span>
            <span className="d-flex g-2px fd-column">
              <span>km</span>
              <span className="fs-16px fw-7000">{carDetial?.km}km</span>
            </span>
            <span className="d-flex g-2px fd-column">
              <span>mod</span>
              <span className="fs-16px fw-700">{carDetial?.mod}</span>
            </span>
            <span className="d-flex g-2px fd-column">
              <span>gearBox</span>
              <span className="fs-16px fw-700">{carDetial?.gearBox}</span>
            </span>
            <span className="d-flex g-2px fd-column">
              <span>price</span>
              <span className="fs-16px  fw-700">{carDetial?.price}</span>
            </span>
          </div> */
}
