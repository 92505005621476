import React, { useState, useEffect } from 'react';
import './Login.css';
import { UserAuth } from '../../firebase/Auth';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { toast, ToastContainer } from 'react-toastify';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  // const { login } = useAuth()
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [email, setEmail] = useState('');
  const [height, setHeight] = useState(windowSize.current[1]);
  const [width, setWidth] = useState(windowSize.current[0]);

  const [passwordType, setPasswordType] = useState('password');
  const [password, setPassword] = useState('');
  const { signIn } = UserAuth();

  const navigate = useNavigate();
  const notify = () => toast('וואו זה פשוט !');
  const handlePasswordChange = (evnt) => {
    setPassword(evnt.target.value);
  };
  useEffect(() => {
    setWidth(windowSize.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      try {
        await signIn(email, password).then((userCredential) => {
          const user = userCredential.user;
          console.log('user', user.uid);
          localStorage.setItem('userId', user.uid);
        });
        toast.success('התחברת בהצלחה ');
        navigate('/');
      } catch (e) {
        toast.error(` התחברות נכשלה :${e}`);
      }
    } else {
      toast.error('חייב להכניס כתובת תקינה!');
    }
  };
  return (
    <div className={width > 500 ? 'login' : 'loginMobile'}>
      <div className='container login_container'>
        <form onSubmit={handleSubmit} className='login_form'>
          <div className='row' dir='rtl'>
            <div className='col'>
              <h2 className='mb-4 textCustom-title-1'>התחברות</h2>
            </div>
          </div>
          <div className='row'>
            <div className='textCustomLogin col'>
              <label>אימייל</label>
              <input
                autoFocus=''
                type='email'
                className='textCustom text-r form-control  sign_input'
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <div className='row'>
            <div className='textCustomLogin tra input-field pt-2 col'>
              <label>סיסמה</label>
              <input
                type={passwordType}
                name='password'
                autocomplete='current-password'
                className='textCustom text-r form-control sign_input'
                required=''
                onChange={handlePasswordChange}
                value={password}
                //placeholder="הכנס סיסמה:"
              />
              <button className=' btn-t ' onClick={togglePassword}>
                <Icon icon={eye} size={15} />
              </button>

              {/* <i
                class='far fa-eye'
                id='togglePassword'
                style='margin-left: -30px; cursor: pointer;'
              ></i> */}
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col'>
              <button
                className='textCustomLogin login_btn mb-4'
                type='submit'
                onSubmit={notify}
              >
                {' '}
                התחבר
                <ToastContainer />
              </button>
            </div>
          </div>
          <Link to='/signup' className='textCustomLogin registerLink'>
            הירשם במקום
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
