// const  = props;
export const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,

  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 600,
  pauseOnHover: true,
  // adaptiveHeight: true,

  pauseOnDotsHover: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const setting = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
};
export const settingMobile = {
  dots: false,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
};

export const settingCardsShopTwo = {
  dots: false,
  arrows: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
};






// extra value for the slider
//export const setting = {
  // lazyLoad: "progressive",
  //dots: false,
 // arrows: false,
  //infinite: false,
  //autoplay: false,
  //autoplaySpeed: 5000,
  //rtl:true,

  //speed: 300,
 // slidesToShow: 5,
  //slidesToScroll: 5,
  // adaptiveHeight: true,
  // responsive: [
  //   // {
  //   //   breakpoint: 2200,
  //   //   settings: {
  //   //     slidesToShow: 4,
  //   //     slidesToScroll: 4,
  //   //   },
  //   // },

  //   {
  //     breakpoint: 1485,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 3,
  //     },
  //   },
  //   {
  //     breakpoint: 1200,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 992,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 576,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  // ],
  //};