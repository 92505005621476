import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { settings } from '../../components/Slider/sliderSettings';

const CarCardLow = ({ det, index }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [width, setWidth] = useState(windowSize.current[0]);

  const navigate = useNavigate();
  //const uid = carDetial.id

  const moreDetails = (id) => {
    // event.preventDefault();
    navigate(`/product-page?id=${id}`);
  };
  useEffect(() => {
    setWidth(windowSize.current[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.current[0]]);
  return (
    <article>
      <div
        className={
          width > 600 ? 'pro-thumb home-2-pro ' : 'pro-thumb home-2-pro-mobile '
        }
      >
        <Slider {...settings}>
          {det &&
            det.images &&
            det.images.map((det01, index) => {
              return (
                <img
                  key={index}
                  className='img-fluid-text img-fluid '
                  id=''
                  src={det01}
                  alt='Product-Image'
                />
              );
            })}
        </Slider>
      </div>
      <div className='pointer' onClick={() => moreDetails(det.key)}>
        <div className='pro-info'>
          <div className='pro-category '>
            <span
              className={width > 600 ? ' home-two-t-1' : ' home-two-t-1-mobile'}
            >
              <span className={width > 600 ? 'modStyle' : 'modStyle-cus-0'}>
                {det.man} {''}
                {det.mod}
              </span>
            </span>
          </div>
          <span className=' textCustom-det-card-sub-1'>{det.year}</span>
          <div className='title-2-main'>
            <span className='textCustomArea mr-2 homw-tow-t-2'>{det.area}</span>
            <img
              alt=''
              className='w28'
              src='/assets/new-images/locationIcon.png'
            />
          </div>

          <div className='custom-grid-main  main-r-text00'>
            <div className='custom-grid-row'>
              <span className='custom-grid-col  '>
                <span className='textCustom-det-card mr-2'>ת.הילוכים:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.gearBox}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span className='textCustom-det-card mr-2'>סוג יד:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.hand}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span className='textCustom-det-card mr-2'>ק״מ:</span>
                <span className='custom-grid-col-sub textCustom-det-card-sub'>
                  {det.km}
                </span>
              </span>
              <span className='custom-grid-col'>
                <span
                  className={
                    width > 600
                      ? 'textCustom-det-card-sub-2 mr-2 '
                      : 'textCustom-det-card-sub-2-mobile mr-2 '
                  }
                >
                  מחיר:
                </span>
                <span
                  className={
                    width > 600
                      ? 'custom-grid-col-sub textCustom-det-card-sub-2'
                      : 'custom-grid-col-sub textCustom-det-card-sub-2-mobile'
                  }
                >
                  {det.price}₪
                </span>
              </span>
            </div>
          </div>
          <div className='home-2-btn mt-3'>
            {/* <Link to="/product-page" className="btn slider-btn-n swipe-to-top"> */}
            <button
              className='btn slider-btn-n swipe-to-top slider-btn-text-22 mb-2'
              onClick={() => moreDetails(det.key)}
            >
              יצירת קשר
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </article>
  );
};

export default CarCardLow;
