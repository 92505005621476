import React, { useState, useEffect } from 'react';
import { Fragment, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cars_filter_data from '../../cars_filter_data.json';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  OPEN_SEARCH_BAR,
  OPEN_MENULIST_POPUP,
  MAIN_FILTER,
} from '../../actions/types';
// import { collection, getDocs, where, query } from 'firebase/firestore';
// import { db } from '../../firebase/config';
import './index.css';
import { UserAuth } from '../../firebase/Auth';

const Header = ({ props }) => {
  // new zone //
  const [data, setData] = useState(cars_filter_data);
  const [carType, setCarType] = useState([]);
  const [man, setMan] = useState([]);
  const [mod, setMod] = useState([]);
  const [smods, setSMod] = useState([]);
  const [year, setYear] = useState([]);
  const [engCap, setEngCap] = useState([]);
  const [gearBox, setGearBox] = useState([]);
  // const [Price, setPrice] = useState([]);
  const [area, setArea] = useState([]);
  const [hand, setHand] = useState([]);
  const [types, setTypes] = useState([]);

  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggleSearchbar, setToggleSearchbar] = useState(false);
  const [toggleMenlist, setToggleMenlist] = useState(false);
  // const [screenWidth, setScreenWidth] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = UserAuth();
  // const inputRef = useRef(null);
  const [formValues, setFormValues] = useState({
    carType: '',
    mod: '',
    man: '',
    smod: '',
    year: '',
    engCap: '',
    gearBox: '',
    area: '',
    hand: '',
  });

  const FilterBox = ({ filterType, label, SendDataToParent }) => {
    const [showOptions, setShowOpthions] = useState(false);
    const inRef = useRef(null);

    // const handleFilter = async (opthion, label) => {
    //   // console.log(opthion,"set selection options options", label)
    //   const q = query(
    //     collection(db, 'carDetail'),
    //     where(label, '==', opthion)
    //     //  where("man", "==", opthion),
    //     //  where("mod", "==", opthion),
    //     // where("engCap", "==", opthion),
    //     // where("gearBox", "==", opthion)
    //   );
    //   const querySnapshot = await getDocs(q);

    //   setDetails(
    //     querySnapshot?.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    //   );
    // };

    ///////////////////
    const values = useSelector((state) => state.settings.mainFilter);
    //////////////////
    function setName(name) {
      let dir = {
        carType: 'סוג הרכב',
        man: 'יצרן',
        mod: 'דגם',
        smod: 'תת דגם',
        year: 'שנה',
        price: 'מחיר',
        engCap: 'נפח מנוע',
        gearBox: 'ת הילוכים',
        km: 'ק"מ',
        ownerShip: ' בחר בעלות',
        area: 'אזור בארץ',
        hand: 'סוג יד',
      };
      return dir[name];
    }

    return (
      /*
            //inRef.current.value = 'null';
        <span className='fb-0px fg-2  btlr-100 bblr-100 bc-white d-flex ai-center 
    */
      <div
        onClick={() => {
          filterType.length > 0 && setShowOpthions(!showOptions);
        }}
        className={
          filterType.length > 0
            ? 'pointer .mxh-52 mxw-270 mw-270px'
            : '.mxh-52 mxw-270 mw-270px'
        }
        dir='rtl'
      >
        {/* <div className='d-flex p-relative   m-1 pt-2'> */}
        <div
          className={
            'd-flex p-custom-input m-1 ' +
            (filterType.length > 0 ? 'p-data-in' : '')
          }
        >
          <span className='fb-0px fs-0 fg-1 bc-white bbrr-40 btrr-40 p-9'>
            {/* {filterType.req && <label></label>} */}
            <label className='ws-nowarp'>{setName(label)}</label>
          </span>
          <span className='d-flex jc-space-between fb-0px  fs-0  fg-1 bc-white c-black  fontRubik in-p-padding'>
            <span
              ref={inRef}
              className='bc-white fontRubik  c-black'
              id='custome'
            >
              {values[label]}
            </span>
            {/*beni {values[label] && (
              <button
                className='bc-transparent b-0 pr-2px c-red'
                onClick={dispatch({
                  type: REMOVE_FROM_FILTER,
                  payload: label,
                })}
              >
                x
              </button>
            )} */}
            {showOptions && (
              <div className=' p-absolute d-flex fd-column bc-white c-black g-8px zi-100 fb-60 custom-dropdown'>
                {filterType.map((opt) => (
                  <div
                    className='pointer cm-filter-option m-0px p-8px'
                    onClick={() => {
                      SendDataToParent(opt, label);
                      setShowOpthions(false);
                      //handleFilter(opt, label);
                    }}
                    key={opt}
                  >
                    {opt}
                  </div>
                ))}
              </div>
            )}
          </span>

          <span className='fb-0px fg-1 pointer btlr-40 bblr-40 bc-white d-flex ai-center jc-center'>
            {filterType.length > 0 &&
              (!showOptions ? (
                <FontAwesomeIcon
                  style={{ fill: 'black' }}
                  icon={faCircleArrowDown}
                  onClick={() => {
                    showOptions
                      ? setShowOpthions(false)
                      : setShowOpthions(true);
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ fill: 'black' }}
                  icon={faClose}
                  onClick={() => {
                    showOptions
                      ? setShowOpthions(false)
                      : setShowOpthions(true);
                  }}
                />
              ))}
          </span>
        </div>
      </div>
    );
  };

  // end zone //
  // beni
  useEffect(() => {
    //let width = window.innerWidth;
    //setScreenWidth(width);
    GetCardetails();
    //if (location.pathname === '/shop-search' || location.pathname === '/shop') {

    if (location.pathname === '/shop') {
      setToggleSidebar(true);
      setToggleSearchbar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let array = [];
    for (let i in data) {
      array.push(data[i]);
    }
    if (location.pathname) setData(array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function cleanMenu() {
    setCarType([]);
    setMan([]);
    setMod([]);
    setSMod([]);
    setYear([]);
    setEngCap([]);
    setGearBox([]);
    setArea([]);
    setHand([]);
    // eslint-disable-next-line
    let cleanValue = {
      carType: '',
      man: '',
      mod: '',
      smod: '',
      year: '',
      engCap: '',
      gearBox: '',
      area: '',
      hand: '',
    };
    dispatch({
      type: MAIN_FILTER,
      payload: cleanValue,
    });
    GetCardetails();
  }

  async function GetCardetails() {
    // const local = [];
    // const querySnapshot = await getDocs(collection(db, 'carDetail'));
    // querySnapshot.forEach((doc) => {
    //   local.push({
    //     ...doc.data(),
    //     key: doc.id,
    //   });
    // });

    let carTypeForShopSearch;
    //beni
    if (location.pathname === '/shop-search') {
      carTypeForShopSearch = new URLSearchParams(location.search).get(
        'carType'
      );

      setFormValues((prevValues) => ({
        carType: carTypeForShopSearch,
        man: '',
        mod: '',
        smod: '',
        year: '',
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      setCarType(carTypeForShopSearch);
      setTypes(data[0][carTypeForShopSearch]);
      setMan([...new Set(data[0][carTypeForShopSearch].map((el) => el.man))]);
    }
  }
  const values = useSelector((state) => state.settings.mainFilter);

  const handleSubmit = (event) => {
    event.preventDefault();
    // eslint-disable-next-line
    navigate(`/shop-search?carType=${values['carType']}`);
    // navigate(
    //   `/shop-search?carType=${values['carType']}&man=${values['man']}&mod=${values['mod']}&smod=${values['smod']}&year=${values['year']}&engCap=${values['engCap']}&gearBox=${values['gearBox']}&area=${values['area']}&price=${values['hand']}`
    // );
  };

  // const handleSubmit01 = (event) => {
  //   event.preventDefault();
  //   navigate(`/shop-search?man=${inputRef.current.value}`);
  //   console.log('handleSubmit01 function called');
  // };

  const onClickSideBarToggle = (e) => {
    e.preventDefault();
    if (location.pathname !== '/shop' && location.pathname !== '/shop-search')
      navigate('/shop');

    setToggleSidebar(false);
    setToggleSidebar(!toggleSidebar);
    dispatch({
      type: OPEN_SEARCH_BAR,
      payload: !toggleSearchbar,
    });
    // if (location.pathname === '/shop' || screenWidth <= 1050) {
    //   setToggleSidebar(!toggleSidebar);
    //   setToggleSearchbar(false);
    // }
    // if (location.pathname !== '/shop' && screenWidth > 1050) {
    //   setToggleSidebar(false);
    //   setToggleSearchbar(!toggleSearchbar);
    //   dispatch({
    //     type: OPEN_SEARCH_BAR,
    //     payload: !toggleSearchbar,
    //   });
    // }
  };

  const logOutFunc = async () => {
    console.log('logOut-Func from header line: 335');
    localStorage.removeItem('userId');
    logout().then(
      function () {},
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  };

  const menulist = () => {
    dispatch({
      type: OPEN_MENULIST_POPUP,
      payload: !toggleMenlist,
    });
    setToggleMenlist(!toggleMenlist);
  };

  // const handlesidebarmenu = () => {
  //   navigate(`/shop`);
  //   setToggleSidebar(!toggleSidebar);
  //   setToggleSearchbar(false);
  // };

  useEffect(() => {
    dispatch({
      type: MAIN_FILTER,
      payload: formValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const SendDataToParent = async (opt, label) => {
    // the callback. Use a better name
    // console.log(opt, label);
    if (label === 'carType') {
      setMod([]);
      setSMod([]);
      setTypes([]);
      setYear([]);
      setEngCap([]);
      setGearBox([]);
      setHand([]);
      setArea([]);
      setFormValues((prevValues) => ({
        carType: opt,
        man: '',
        mod: '',
        smod: '',
        year: '',
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      // const res = data.carType.filter(
      //   (carTypeIndex) => carTypeIndex === opt
      // );
      setCarType(opt);
      setTypes(data[0][opt]);
      setMan([...new Set(data[0][opt].map((el) => el.man))]);
    } else if (label === 'man') {
      setMod([]);
      setSMod([]);
      setYear([]);
      setGearBox([]);
      setEngCap([]);
      setHand([]);
      setArea([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: opt,
        mod: '',
        smod: '',
        year: '',
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      if (carType === 'משאיות') {
        setYear(data[0].year);
      } else {
        const res = types.filter((el) => el.man === opt);
        setMod([...new Set(res.map((el) => el.mod))]);
      }
    } else if (label === 'mod') {
      setSMod([]);
      setYear([]);
      setGearBox([]);
      setEngCap([]);
      setHand([]);
      setArea([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: opt,
        smod: '',
        year: ' ',
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      if (carType === 'פרטי') {
        const res = types.filter((el) => el.mod === opt);
        setSMod([...new Set(res.map((el) => el.smod))]);
        setYear(data[0].year);
      } else {
        setYear(data[0].year);
      }
    } else if (label === 'smod') {
      setYear([]);
      setGearBox([]);
      setEngCap([]);
      setHand([]);
      // eslint-disable-next-line
      setArea([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: prevValues.mod,
        smod: opt,
        year: '',
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      setYear(data[0].year);
    } else if (label === 'year') {
      setGearBox([]);
      setEngCap([]);
      setHand([]);
      setArea([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: prevValues.mod,
        smod: prevValues.smod,
        year: opt,
        engCap: '',
        gearBox: '',
        area: '',
        hand: '',
      }));
      setEngCap(data[0].engCap);
    } else if (label === 'engCap') {
      setGearBox([]);
      setHand([]);
      setArea([]);
      // eslint-disable-next-line
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        smod: prevValues.smod,
        mod: prevValues.mod,
        year: prevValues.year,
        engCap: opt,
        gearBox: '',
        area: '',
        hand: '',
      }));
      setGearBox(data[0].gearBox);
    } else if (label === 'gearBox') {
      setHand([]);
      setArea([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: prevValues.mod,
        smod: prevValues.smod,
        year: prevValues.year,
        engCap: prevValues.engCap,
        gearBox: opt,
        area: '',
        hand: '',
      }));
      setArea(data[0].area);
    } else if (label === 'area') {
      setHand([]);
      setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: prevValues.mod,
        smod: prevValues.smod,
        year: prevValues.year,
        engCap: prevValues.engCap,
        gearBox: prevValues.gearBox,
        area: opt,
        hand: '',
      }));
      setHand(data[0].hand);
    } else if (label === 'hand') {
      await setFormValues((prevValues) => ({
        carType: prevValues.carType,
        man: prevValues.man,
        mod: prevValues.mod,
        smod: prevValues.smod,
        year: prevValues.year,
        engCap: prevValues.engCap,
        gearBox: prevValues.gearBox,
        area: prevValues.area,
        hand: opt,
      }));
    }
  };
  var filterTypes = [
    {
      label: 'carType',
      opthion: data[0].carType,
      req: true,
      sm: false,
    },
    {
      label: 'man',
      opthion: man,
      req: true,
      sm: true,
    },
    {
      label: 'mod',
      opthion: mod,
      req: false,
      sm: false,
    },
    {
      label: 'smod',
      opthion: smods,
      req: false,
      sm: false,
    },
    {
      label: 'year',
      opthion: year,
      req: false,
      sm: true,
    },
    {
      label: 'engCap',
      opthion: engCap,
      req: false,
      sm: false,
    },
    {
      label: 'gearBox',
      opthion: gearBox,
      req: false,
      sm: false,
    },
    {
      label: 'area',
      opthion: area,
      req: false,
      sm: false,
    },
    {
      label: 'hand',
      opthion: hand,
      req: false,
      sm: false,
    },
  ];
  return (
    <Fragment>
      {/*Desktop Header*/}
      <header
        id='headerOne'
        className='header-area header-one header-desktop  header-v'
      >
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-12 col-lg-9 col-xl-9 '>
              <nav className='navbar navbar-expand-lg'>
                <div className='navbar-collapse'>
                  <ul className='navbar-nav'>
                    <li className='nav-item dropdown li-c'>
                      <a
                        className='nav-link dropdown-toggle li-t grr--over--open'
                        href='#'
                        onClick={menulist}
                      >
                        {/* <Link to='/MyAdds' className='he--icon'>
                          <i class='fa-regular fa-user'></i>
                        </Link> */}
                        <i className='fa-regular fa-user' size='8x'></i>
                      </a>
                      {toggleMenlist ? (
                        <ul className='dropdown--popup'>
                          <li>
                            <Link to='/MyAdds' className='pr he--icon'>
                              {/* <i class='fa-solid fa-car' onClick={menulist}></i> */}
                              <img
                                alt=''
                                className='img-head3'
                                src='/assets/new-images/my-note-icone.png'
                                onClick={menulist}
                              />
                            </Link>
                          </li>

                          <li>
                            <Link to='/' className='he--icon'>
                              <i
                                className='fa-solid fa-arrow-right-from-bracket'
                                onClick={logOutFunc}
                              ></i>
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </li>
                  </ul>
                  {/* <form className='form-inline'>
                    <div className='search-field-module'>
                      <div className='search-field-wrap '>
                        <div>
                          <button
                            onClick={handleSubmit01}
                            className='btn inpt-b swipe-to-top'
                          >
                            <FontAwesomeIcon
                              style={{ fill: 'black' }}
                              icon={faMagnifyingGlass}
                            />
                          </button>

                          <input
                            ref={inputRef}
                            className='form-control inpt-c '
                            type='text'
                            placeholder='חפש...'
                            onChange={(e) => setCarType(e.target.value)}
                            dir='rtl'
                          />
                        </div>
                      </div>
                    </div>
                  </form> */}
                  <div
                    className={
                      toggleSidebar || toggleSearchbar
                        ? 'circle-img'
                        : 'circle-img-2'
                    }
                  >
                    <img
                      alt=''
                      className='img-head2'
                      src={
                        toggleSidebar || toggleSearchbar
                          ? '/assets/new-images/search.png'
                          : '/assets/new-images/searchWhite.png'
                      }
                      onClick={(e) => onClickSideBarToggle(e)}
                    />
                  </div>
                  <div>
                    <Link to='/add-product' className='he--icon'>
                      <img
                        alt=''
                        className='img-head'
                        src='/assets/new-images/note-icons.png'
                      />
                    </Link>
                  </div>
                </div>
              </nav>
            </div>
            <div className='col-12 col-lg-3 col-xl-3 d-flex justify-content-end height-c'>
              <div className='navbar-lang'></div>
              <ul className='pro-header-right-options'>
                <Link to='/'>
                  <li className='dropdown'>
                    <img alt='' src='/assets/new-images/head-logo-3.png' />
                    <span className='head-3'>חללית</span>
                    <strong className='head-33'>קאר</strong>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/*header for mobile  */}
      <header
        id='headerOne'
        className='header-area header-one header-mobile-new  header-v'
      >
        <div className='container-fluid'>
          <div className='row mobile-header-align'>
            <div className=' '>
              <nav className=''>
                <div className='mobile-side-1'>
                  <div
                    className={toggleSidebar ? 'circle-img' : 'circle-img-2'}
                  >
                    <img
                      alt=''
                      // style={{cursor: pointer}}
                      className='img-head2'
                      src={
                        toggleSidebar
                          ? '/assets/new-images/search.png'
                          : '/assets/new-images/searchWhite.png'
                      }
                      onClick={(e) => onClickSideBarToggle(e)}
                    />
                  </div>
                  <div>
                    <Link to='/add-product' className='he--icon'>
                      <img
                        alt=''
                        className='img-head'
                        src='/assets/new-images/note-icons.png'
                      />
                    </Link>
                  </div>
                </div>
              </nav>
            </div>
            <div className=' d-flex justify-content-end height-c'>
              <div className='navbar-lang'></div>
              <ul className='pro-header-right-options'>
                <li className='dropdown'>
                  <a href='/'>
                    <img
                      alt=''
                      className='mini-logo'
                      src='/assets/new-images/head-logo-3.png'
                    />
                    <span className='head-3'>חללית</span>
                    <strong className='head-33'>קאר</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className=' '>
              <nav className='navbar '>
                <div className='navbar-collapse'>
                  <ul className='navbar-nav'>
                    <li className='nav-item dropdown li-c'>
                      <a
                        className='nav-link dropdown-toggle li-t'
                        onClick={menulist}
                      >
                        {/* <Link to='/add-product' className='he--icon'>
                          <i
                            class='fa-solid fa-car'
                            size={'4px'}
                            onClick={menulist}
                          ></i>
                        </Link> */}
                        <i
                          className='fa-solid fa-car'
                          size='lg'
                          onClick={menulist}
                        ></i>
                      </a>

                      {toggleMenlist ? (
                        <ul className='dropdown--popup'>
                          <li>
                            <Link to='/MyAdds' className='he--icon'>
                              {/* <i class='fa-solid fa-car' onClick={menulist}></i> */}
                              <img
                                alt=''
                                className='img-head3-mobile'
                                src='/assets/new-images/my-note-icone.png'
                                onClick={menulist}
                              />
                            </Link>
                          </li>

                          <li>
                            <Link to='/' className='he--icon'>
                              <i
                                className='fa-solid fa-arrow-right-from-bracket'
                                onClick={logOutFunc}
                              ></i>
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* //Sticky Header */}

      {/* //Mobile Header */}
      <header id='headerMobile' className='header-area header-mobile'>
        <div className='header-maxi bg-header-bar '>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-6 pr-0 flex-col'>
                <div className='navigation-mobile-container'>
                  <nav
                    id='navigation-mobile'
                    className={
                      toggleSidebar ? 'navigation-active b-color' : 'b-color'
                    }
                  >
                    <div className='pb-4 side-cross'>
                      <img
                        alt=''
                        onClick={() => {
                          setToggleSidebar(false);
                          dispatch({
                            type: OPEN_SEARCH_BAR,
                            payload: false,
                          });
                        }}
                        className='pointer'
                        src='/assets/new-images/cross-2.png'
                      />
                      <span className='side-cross-text-2' />
                      <span className='side-cross-text-2' />
                      <span className='side-cross-text-1'>סינון</span>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className='col-12 col-md-12 accordian-widget  m-0 p-0 btn-group-main '>
                        <div>
                          <FilterBox
                            filterType={filterTypes[0].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[0].label}
                            key={filterTypes[0].label}
                          />
                          <FilterBox
                            filterType={filterTypes[1].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[1].label}
                            key={filterTypes[1].label}
                          />
                          {carType !== 'משאיות' ? (
                            <FilterBox
                              filterType={filterTypes[2].opthion}
                              SendDataToParent={SendDataToParent}
                              label={filterTypes[2].label}
                              key={filterTypes[2].label}
                            />
                          ) : (
                            <span></span>
                          )}
                          {carType === 'פרטי' ? (
                            <FilterBox
                              filterType={filterTypes[3].opthion}
                              SendDataToParent={SendDataToParent}
                              label={filterTypes[3].label}
                              key={filterTypes[3].label}
                            />
                          ) : (
                            <span></span>
                          )}
                          <FilterBox
                            filterType={filterTypes[4].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[4].label}
                            key={filterTypes[4].label}
                          />
                          <FilterBox
                            filterType={filterTypes[5].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[5].label}
                            key={filterTypes[5].label}
                          />
                          <FilterBox
                            filterType={filterTypes[6].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[6].label}
                            key={filterTypes[6].label}
                          />
                          <FilterBox
                            filterType={filterTypes[7].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[7].label}
                            key={filterTypes[7].label}
                          />
                          <FilterBox
                            filterType={filterTypes[8].opthion}
                            SendDataToParent={SendDataToParent}
                            label={filterTypes[8].label}
                            key={filterTypes[8].label}
                          />
                          <div
                            className='left pointer side-cross-text-2 mr-4 pb-3 pr-2 mt-2'
                            onClick={() => cleanMenu()}

                            // onClick={() => {
                            //   cleanMenu();
                            // }}
                          >
                            {' '}
                            נקה הכל
                          </div>
                          {/* בשביל פיתוח עתידי לא למחוק */}
                          {/* <div className='slide-one-box mt-1 mb-3'>
                              <Link to='/shop-search'>
                                <span className='pointer text-two-slide'>
                                  חיפושים אחרונים
                                </span>
                              </Link>
                              <img
                                alt=""
                                className='ml-2'
                                src='/assets/new-images/clock.png'
                              />
                            </div> */}

                          {/* <Link to={`/shop-search?carType=${carType}man=${man}mod=${mod}smod=${smod}year=${year}engCap=${engCap}gearBox=${gearBox}`   } className="btn slider-btn-n-slide swipe-to-top"> */}
                          {/* <button
                            type='submit'
                            className='btn slider-btn-n btnSliderSend swipe-to-top slider-btn-text-22 '
                          >
                            חפש
                          </button> */}
                          {/* </Link> */}
                        </div>
                      </div>
                    </form>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* חשוב לא למחוק  */}
      {/* <div
        className='container-fluid fluid-w shaoib'
        style={toggleSearchbar ? { display: 'block' } : { display: 'none' }}
      >
         <div className='container search-main-d'>
          <div className='container search-br'>
            <div className='row row-h'>
               <div className='col-4 search-br-h'>
                <button
                  onClick={handleSubmit}
                  href='#'
                  className='btn slider-btn-n-footer swipe-to-top'
                >
                  <span className='slider-btn-text'>בחיפוש</span>
                </button>
                <a
                  href='#'
                  className='btn slider-btn-n-footer-2 swipe-to-top btn-left'
                >
                  <span className='slider-btn-text' onClick={handlesidebarmenu}>
                    חיפוש מתקדם
                  </span>
                </a>
              </div> 
               <div className='col-8 search-br-h'>
                <div className='select-control main-home shoaib'>
                  <label className='lebel-t custom_label'>אזור</label>
                  <select
                    onChange={(e) => setArea(e.target.value)}
                    className='select select-sb'
                  >
                    <option value=''> Area : </option>
                  </select>
                </div>
                <div className='select-control main-home'>
                  <label className='lebel-t '>מחיר</label>₪
                  <div className='dn'>
                    <select
                      onChange={(e) => setPrice(e.target.value)}
                      className='select select-sb'
                    >
                      <option value=''> Price </option>
                      <option value='10000'>10000</option>
                    </select>
                  </div>
                </div>
                <div className='select-control main-home'>
                  <label className='lebel-t'>דגם</label>
                  <select
                    onChange={(e) => setMod(e.target.value)}
                    className='select select-sb'
                  >
                    <option value=''> Mod </option>
                    <option value='1999'>1999</option>
                  </select>
                </div>
                <div className='select-control main-home'>
                  <label className='lebel-t'>יצרן</label>
                  <select
                    onChange={(e) => setMan(e.target.value)}
                    className='select select-sb'
                  >
                    <option value=''> Manufacturer </option>
                  </select>
                </div>
              </div> 
            </div>
          </div>
        </div> 
          </div>  */}
    </Fragment>
  );
};

export default Header;
